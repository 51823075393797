<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Competency Mapping
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="mappings"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
          :loading="loading"
      >
        <template v-slot:item.actions="{ item }">
          <v-chip
              class="ma-2"
              :to="{ name: 'competency-mapping', params: { hash: item.hash }}"
              color="primary"
          >
            View
          </v-chip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add mapping</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-select
                      :items="tenants"
                      v-model="newMapping.from"
                      label="From"
                      dense
                  ></v-select>
                </v-row>
                <v-row>
                  <v-select
                      :items="tenants"
                      v-model="newMapping.to"
                      label="To"
                      dense
                  ></v-select>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>





<script>
export default {
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'From',
          align: 'start',
          sortable: true,
          value: 'from.tenant_name',
        },
        {
          text: 'To',
          align: 'start',
          sortable: true,
          value: 'to.tenant_name',
        },
        { text: 'Hash', value: 'hash' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loading: true,
      mappings: [],
      tenants: [],
      dialog: false,
      newMapping: {
        from: null,
        to: null
      },
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL +"competency-mapping").then(response => {
      this.mappings = response.data.mappings
      this.loading = false;
    });
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "dashboard/stats/tenants").then(response => {
      response.data.data.forEach(function (row) {
        this.tenants.push({
          text: row.tenant_name,
          value: row.id
        });
      }, this);
    });
  },
  methods: {
    save(){
      console.warn(this.newMapping)
      this.$http.put(
          process.env.VUE_APP_CONTROL_API_URL + "competency-mapping/",
          this.newMapping
      ).then(response => {
        this.$toast.success('Successfully created mapping '+response.data.from.tenant_name+'->'+ response.data.to.tenant_name);
        this.$router.push('/competency-mapping/'+response.data.hash);
      })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.$toast.error(error.response.data.msg);
            }
          });
      this.close()
    },
    close(){
      this.newMapping = {from:null, to:null};
      this.dialog = false;
    },


  },
  name: "tools-mapper"
}

</script>

<style scoped>

</style>
