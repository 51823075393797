<template>
  <v-layout child-flex>

    <v-card>
      <v-card-title>
        Cron Statistics
      </v-card-title>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            lg="6"
        >
          <base-material-stats-card
              color="green"
              icon="mdi-clock-fast"
              title="Running Tasks"
              v-bind:value="runningTasks.length.toString()"
          />
        </v-col>
        <v-col
            cols="12"
            sm="6"
            lg="6"
        >
          <base-material-stats-card
              color="red"
              icon="mdi-alert-circle"
              title="Errors"
              v-bind:value="errors.length.toString()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="12"
            lg="6"
        >
          <v-card>
            <v-card-title>Longest Running</v-card-title>
            <v-card-text>
              <v-list>
                <div v-for="(tasks, env) in groupedRunningTasks" :key="env">
                  <v-subheader>{{ env }}</v-subheader>
                  <v-list-item
                      v-for="(task, i) in tasks.slice(0, 10)"
                      :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <router-link :to="{ name: 'tenant', params: { id: task.tenant_id }}">
                          <strong>{{ task.tenant }}</strong>
                        </router-link>
                        : {{ task.msg }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item v-if="runningTasks.length > 40">
                  <v-list-item-content>
                    <v-list-item-title>
                      plus {{ runningTasks.length - 40 }} more
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            lg="6"
            v-if="errors.length"
        >
          <v-card>
            <v-card-title>Error Log</v-card-title>
            <v-card-text>
              <v-list>

                <v-list-item
                    v-for="(err,i) in errors"
                    :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>{{ err.tenant }}</strong>: {{ err.msg }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-card>

  </v-layout>
</template>


<script>
export default {
  data() {
    return {
      tenants: [],
      runningTasks: [],
      errors: [],
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/cron").then(response => {
      this.tenants = response.data.tenants;
      this.runningTasks = [];
      this.tenants.forEach(function (tenant) {
        this.getStats(tenant);
      }, this);
    });
  },
  methods: {
    getStats(tenant) {
      if (tenant.cron_tasks === null) {
                this.errors.push({
                  msg: 'No data',
                  tenant: tenant.tenant_name
                 });
      } else  {
        let tasks = JSON.parse(tenant.cron_tasks);
        tasks.forEach(function (row) {
          this.runningTasks.push({
            elapsed: row.elapsed,
            tenant: tenant.tenant_name,
            tenant_id: tenant.id,
            env: tenant.environment_name,
            msg: row.task + ' for ' + row.elapsed_formatted
          });
        }, this);
        this.runningTasks.sort(function (a, b) {
          return b.elapsed - a.elapsed;
        });
      }
    }
  },
  computed: {
    groupedRunningTasks() {
      return this.runningTasks.reduce((acc, task) => {
        if (!acc[task.env]) {
          acc[task.env] = [];
        }
        acc[task.env].push(task);
        return acc;
      }, {});
    }
  },
  name: "cron"
}
</script>

<style scoped>

</style>
