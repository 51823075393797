<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Create a Release
        -&gt;
        <v-btn class="ml-2" :to="{ name: 'tools-deploy-release' }">Deploy Release</v-btn>
      </v-card-title>
      <v-card-text>
        <p class="text">
          Make sure to run <strong>Upload Straker translations tool</strong> before creating the release or after and then add it as a patch <br/>
          Create a ticket called <strong>Translations for Release x.xx</strong> and attach the translation request XML file from Straker here and set the ticket status to QA.
        </p>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="apps" v-model="release.app" :disabled="loading.create" @input="appChange"
              label="Select an app" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="d-flex flex-row">
            <v-select :items="tags" v-model="release.tag" @input="onTagChange" :loading="loading.tags"
              :disabled="loading.create || !release.app" label="Select an tag" />
            <v-dialog v-model="createTagDialog" @input="dialogChange()" :disabled="!release.app || loading.create"
              max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-4 mt-3">
                  <v-icon>mdi-plus</v-icon>
                  Tag
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Create tag</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-select :items="branches" v-model="newTag.branch" :loading="loading.branches"
                      label="Select an branch" />
                  </v-row>
                  <v-row>
                    <v-text-field v-model="newTag.tag" label="Tag"></v-text-field>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="createTagDialog = false">Cancel</v-btn>
                  <v-btn color="primary" text @click="createTag" :loading="loading.create"
                    :disabled="!newTag.branch || !newTag.tag">Create</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row v-if="envs[release.app]">
          <v-col cols="12" md="6">
            <v-select :items="envs[release.app]" v-model="release.env" :disabled="loading.create"
              label="Select an environment (Only needed for strive frontend)" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-2 mr-1" color="primary" @click="createRelease()" :loading="loading.create"
          :disabled="!release.app || !release.tag || (envs[release.app] && !release.env) || loading.tags || loading.create">
          Create Release
        </v-btn>
        {{ progressMessage }}
      </v-card-actions>

      <v-card-text v-if="output.length > 0">
        <v-row>
          <v-col cols="12">
            <h2>Release output</h2>
            <pre>{{ output }}</pre>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-layout>
</template>

<script>
import { http, apiUrl } from '@/services/http';

export default {
  name: 'ToolsCreateRelease',
  components: {},
  data: () => ({
    release: {
      app: null,
      tag: null,
      env: null,
    },
    createTagDialog: false,
    newTag: {
      tag: null,
      branch: null,
    },
    branches: [],
    tags: [],
    versions: [],
    apps: [],
    envs: {
      bg: [
        { text: 'Dev', value: 'dev' },
        { text: 'Production', value: 'prod' },
      ],
    },
    output: [],
    loading: {
      tags: false,
      branches: false,
      create: false,
    },
    progressMessage: '',
  }),
  async created() {
    const response = await http.get(`${apiUrl}/environment/apps`);
    this.apps = response.data;
  },
  methods: {
    async appChange() {
      this.release.tag = null;
      this.release.env = null;
      this.branches = [];
      this.loading.tags = true;
      await Promise.all([
        this.getTags(),
        this.getEbVersions(),
      ]);
      this.loading.tags = false;
    },
    async dialogChange() {
      if (!this.branches.length) {
        this.loading.branches = true;
        await this.getBranches();
        this.loading.branches = false;
      }
    },
    async getBranches() {
      const params = { app: this.release.app };
      const response = await http.get(`${apiUrl}/repos/branches`, { params });
      this.branches = response.data;
    },
    async getTags() {
      const params = { app: this.release.app };
      const response = await http.get(`${apiUrl}/repos/tags`, { params });
      this.tags = response.data;
    },
    async getEbVersions() {
      const app = this.release.app;
      const env = this.release.env || 'dev';
      const params = { app, env };
      if (app !== 'bg') {
        params['backend-only'] = 1;
      }
      const response = await http.get(`${apiUrl}/release/versions`, { params });
      this.versions = response.data;
    },
    onTagChange() {
      if (this.versions.includes(this.release.tag)) {
        this.$toast.error(`${this.release.tag} version already exists`);
        this.$nextTick(() => {
          this.release.tag = null;
        });
      }
    },
    async createTag() {
      try {
        if (this.tags.includes(this.newTag.tag)) {
          this.$toast.error(`Tag (${this.newTag.tag}) already exists`);
        }
        this.loading.create = true;
        const data = Object.assign({ app: this.release.app }, this.newTag)
        await http.post(`${apiUrl}/repos/tags`, data);
        this.$toast.success(`Tag ${this.newTag.tag} created`);
        await this.getTags();
        this.createTagDialog = false;
      } catch (e) {
        const err = e.response && e.response.data ? e.response.data.error : '?';
        this.$toast.error(`Failed to create tag - ${err}`);
      } finally {
        this.loading.create = false;
      }
    },
    async createRelease() {
      try {
        const startTime = new Date();
        this.loading.create = true;
        this.output = [];
        const response = await http.get(`${apiUrl}/release/app-services`, { params: this.release });
        const services = response.data;
        for (const service of services) {
          this.progressMessage = `Creating ${service} release`;
          const data = Object.assign({}, this.release, { service });
          const response = await http.post(`${apiUrl}/release/create`, data);
          this.output = [...this.output, ...response.data.output];
          this.$toast.info(`Release ${service} - ${this.release.tag} created`);
        }
        const timeDiff = (new Date()) - startTime;
        const msg = `Release ${this.release.tag} created in ${Math.round(timeDiff / 1000)} seconds`
        this.output.push(msg);
        this.$toast.success(msg);
      } catch (e) {
        let err = '?';
        if (e.response && e.response.data) {
          err = e.response.data.error;
          this.output = [...this.output, ...e.response.data.output];
        }
        this.$toast.error(`Failed to create release - ${err}`);
      } finally {
        this.loading.create = false;
        this.progressMessage = '';
      }
    },
  },
  computed: {
  },
}

</script>

<style scoped>
  .text {
    strong {
      color: red;
    }
  }
</style>
