<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'HomePortal2',
    apiSuffix: 'homeportal2',
    tenantDataKey: 'homeportal2_tenant',
    urlKey: 'hp2_url',
    generateUrlString: '.courseprohome.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 4;
      }
    },
  },
  name: "tenant-homeportal2-service"
}
</script>
