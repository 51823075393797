<template>
  <pre>
    {{ prettyXml() }}
  </pre>
</template>

<script>
let format = require('xml-formatter');
export default {
  name: 'PrettyXml',
  props: {
    xml: {
      type: String,
      default: '',
    },
  },
  methods: {
    prettyXml() {
      let formattedXml = format(this.$props.xml);
      console.log(formattedXml);
      return formattedXml;
    },
  },
}
</script>

<style lang="sass">

</style>
