<template>
  <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      @change="search"
      @update:search-input="search"
      clearable
      color="white"
      hide-no-data
      hide-selected
      item-text="Description"
      item-value="API"
      placeholder="Start typing to Search"
      prepend-icon="mdi-magnify"
      return-object
      class="pt-4"
  ></v-autocomplete>
</template>

<script>
export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
  }),
  methods: {
    async search(val) {
      if (this.model) {
        this.$emit('input', this.model);
        this.entries = [];
        this.model = null;
        return;
      }
      // Items have already been loaded
      if (this.items.length > 0) {
        return;
      }
      // Items have already been requested
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        const response = await this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "omnisearch/?val=" + val);
        this.entries = response.data.results;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    items () {
      return this.entries.map(entry => {
        const Description = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name;

        return Object.assign({}, entry, { Description });
      })
    },
  },
}
</script>

<style scoped>

</style>
