<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'HomePortal2',
    hashIndex: 3,
    apiSuffix: 'homeportal2',
    config: [],
    defaultConfig: [

      {
        type:1,
        key: 'asset_path',
        value: '/tenant/demo/overrides'
      },
      {
        type:1,
        key: 'available_languages',
        value: '"a:1:{i:0;s:2:"en";}"'
      },
      {
        type:1,
        key: 'currencyIso',
        value: 'GBP'
      },
      {
        type:1,
        key: 'default_language',
        value: 'en'
      },
      {
        type:1,
        key: 'enableI18nInheritance',
        value: 'true'
      },
      {
        type:1,
        key: 'homeportalRedirectUrl',
        value: ''
      },
      {
        type:1,
        key: 'onlinejoining',
        value: 'true'
      },
      {
        type:1,
        key: 'paymentRedirectUrlBase',
        value: ''
      },
      {
        type:1,
        key: 'postcodeEnabled',
        value: 'false'
      },
      {
        type:1,
        key: 'postfixApi',
        value: '/portal/api/HomePortal'
      },
      {
        type:1,
        key: 'prefixApi',
        valueTemplate: 'https://{{TENANT_NAME}}.coursepro.co.uk'
      },
      {
        type:1,
        key: 'tenant_footer',
        value: 'false'
      },
      {
        type:1,
        key: 'tenant_logo',
        value: 'homeportal.svg'
      },
      {
        type:1,
        key: 'tenant_name',
        value: 'demo'
      },
      {
        type:1,
        key: 'theme_name',
        value: 'default-theme'
      }
    ]
  }),
  name: "tenant-homeportal2"
}
</script>
