<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Seed Tenant with Demo Data
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <OmniSearch @input="tenantSelected"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-2" color="primary" @click="seedTenant()" :loading="loading"
          :disabled="loading || !tenant.id">{{  buttonText }}</v-btn>
      </v-card-actions>

      <v-card-text v-if="output.length > 0">
        <v-row>
          <v-col cols="12">
            <h2>Seeding output</h2>
            <pre>{{ output }}</pre>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-layout>
</template>

<script>
import { http, apiUrl } from '@/services/http';
import OmniSearch from "@/components/OmniSearch";

export default {
  components: {
    OmniSearch,
  },
  data: () => ({
    output: [],
    tenant: {
      id: null,
      name: null,
    },
    loading: false,
  }),
  methods: {
    tenantSelected(tenant) {
      this.tenant = tenant;
    },
    async seedTenant() {
      try {
        this.output = [];
        this.loading = true;
        const response = await http.post(`${apiUrl}/release/seed`, { tenant: this.tenant.name });
        this.output = response.data.output;
        this.$toast.success(`Tenant ${this.tenant.name} seed complete`);
      } catch (e) {
        let err = '?';
        if (e.response && e.response.data) {
          err = e.response.data.error;
          this.output = e.response.data.output;
        }
        this.$toast.error(`Failed to seed tenant - ${err}`);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    buttonText() {
      if (this.tenant.id) {
        return `Seed Tenant - ${this.tenant.name}`;
      }
      return 'Select a Tenant';
    }
  },
}

</script>

<style scoped>
</style>
