<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'CoursePro',
    apiSuffix: 'coursepro',
    tenantDataKey: 'coursepro_tenant',
    urlKey: 'coursepro_url',
    generateUrlString: '.coursepro.co.uk'
  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 1;
      }
      if (type === 'worker') {
        return 2;
      }
      if (type === 'sns') {
        return 5;
      }
    },
  },
  name: "tenant-coursepro-service"
}
</script>
