
import { http, apiUrl } from '@/services/http';

const Tenant = {
  commitConfig: (id, type) => {
    return http.put(`${apiUrl}/tenant/${id}/${type}`);
  },
};

export { Tenant as default };
