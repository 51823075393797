<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'Strive',
    hashIndex: 5,
    apiSuffix: 'strive',
    config: [],
    defaultConfig: [
      {
        type: 1,
        key: 'USE_DB_TENANT',
        value: true
      },
    ]
  }),
  name: "tenant-strive-config"
}
</script>
