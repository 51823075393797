<template>
  <v-layout child-flex>
    <v-card
        class="pa-4 ma-2">
      <v-card-title>
        <TenantBreadcrumb
            v-bind:name="tenant.tenant_name"
            sub="Relations"
        ></TenantBreadcrumb>
      </v-card-title>
      <v-btn
          absolute
          dark
          fab
          bottom
          right
          color="pink"
          @click="newRelation"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-form
          ref="form"
          v-if="tenantIsLoaded"
      >
        <table>
          <tr v-for="(rel, idx) in tenant.relations" :key="rel._id">
            <td>{{tenant.tenant_name}}</td>
            <td>        <v-select
                :items="relationTypes"
                v-model="rel.type"
            ></v-select></td>
            <td>          <v-select
                :items="allTenants"
                v-model="rel.tenant_right"
            ></v-select></td>
            <td>
              <v-icon @click="deleteRelation(idx)">mdi-delete</v-icon>
            </td>
            <td>
              <v-btn v-if="rel.type==1" @click="liveToTest(idx)">Live to test</v-btn>
            </td>
          </tr>
        </table>


      </v-form>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">Save</v-btn>

      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import TenantBreadcrumb from "@/components/TenantBreadcrumb";
export default {
  components: {TenantBreadcrumb},
  data: () => ({
    loading: true,
    tenantIsLoaded: false,
    tenant: {
      tenant_name: 'Loading',
      _id: null
    },
    relationTypes: [
      {
        value: 1,
        text: "is parent of"
      },
      {
        value: 2,
        text: "is child of"
      }
    ],
    allTenants: [],

  }),

  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;
      if (!this.tenant.relations) {
        this.tenant.relations = [];
      }
      this.tenantIsLoaded = true;

    });
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/select").then(response => {
      response.data.forEach(function (row) {
        this.allTenants.push({
          text: row.tenant_name,
          value: parseInt(row.id)
        });
      }, this);
    });


  },
  methods: {
    save: function()
    {
      let patch = {};
      patch.relations = [];
      this.tenant.relations.forEach(function(row) {
      patch.relations.push({
        id: row._id,
        tenant_right: row.tenant_right,
        type: row.type
      });

    });
      this.$http.patch(
          process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id ,
          patch
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        }
        if (response.data.tenant) {
          this.tenant = response.data.tenant;
          if (!this.tenant.relations) {
            this.tenant.relations = [];
          }
          this.$toast.success(this.configType +' relations successfully saved');
        }

      });

    },
    deleteRelation: function(idx){
      this.tenant.relations.splice(idx, 1);
    },
    liveToTest: function(idx){
      const rel = this.tenant.relations[idx];
      if (rel.type !== 1 || !rel.tenant_left || !rel.tenant_right) {
        this.$toast.warning('Not suitable for Live to Test');
        return;
      }

      this.$toast.warning('Not implemented - see console');
      console.log('---------------------------------');
      console.log('Live to Test not uet implemented');
      console.log('Use `./console db livetotest --live ' +  this.tenant.tenant_name + ' --test ' + rel.tenant_right_name + '` instead');
      console.log('---------------------------------');
      // this.$http.post(
      //     process.env.VUE_APP_CONTROL_API_URL + "database/transfer" ,
      //     rel
      // ).then(response => {
      //
      // });


    },
    newRelation: function(){
      this.tenant.relations.push({
        tenant_left: this.tenant._id,
        type: null,
        tenant_right: null
      });
    }
  },
  name: "tenant-relations"
}
</script>

<style scoped>

</style>
