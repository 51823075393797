<template>

    <span>
     {{ getText() }}
    </span>

 </template>

<script>
export default {
  name: 'CronSchedule',
  data () {
    return {
      text: '',
      class: ''
    }
  },
  props: {
    schedule: {
      type: Object,
    },
    execution: {
      type: Object,
    },
  },

  methods: {
    getText() {
      let text = '';
      if ( this.$props.schedule.time) {
        let time = this.$props.schedule.time.type;
        if (time === 'always') {
          text = 'Always';
        } else if (time === 'manual') {
          //might be a chain
          if (this.$props.execution.chain) {
            text = this.ucfirst(this.$props.execution.chain.frequency) + ' (Chain #' + this.$props.execution.chain.order + ')';
          } else {
            text = 'Manual';
          }

        } else if (time === 'delay') {
          text = 'Every ' + this.minsToString(this.$props.schedule.time.min);
        }
      }
      if (this.$props.schedule.run) {
        text += ' (between '+ this.$props.schedule.run.notBefore + ' and ' + this.$props.schedule.run.notAfter + ')';
      }

      if (this.$props.schedule.conditional) {
        text += ' if '+ this.$props.schedule.conditional.variable + ' is ' + (this.$props.schedule.conditional.is || 'true');
      }
      return text;
    },
    ucfirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    minsToString(mins) {
      if (mins < 90) {
        return mins + ' minutes'
      }
      let hours = (mins / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      let out = '';
      if (rhours) {
        out += rhours + ' hours'
      }
      if (rhours && rminutes) {
        out += ' '
      }
      if (rminutes) {
        out += rminutes + ' mins'
      }
      return out;
    },
    spanClass() {
      if (this.$props.expiry === null) {
        return '';
      }
      if (this.$props.expiry === '') {
        return 'success';
      }
      switch(this.state) {
        case -1:
          case 0:
          // expired or expiring soon
          return 'error';
        case 1:
        default:
          return 'success';
          // code block
      }
    },
  },
}
</script>

<style lang="sass">

</style>
