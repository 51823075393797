<template>
  <v-layout child-flex>
    <v-card>LeisureHub Sandbox
      <v-card-title>
        <TenantBreadcrumb
          v-bind:name="tenant.tenant_name"
          sub="Leisure Sandbox"
          >
        </TenantBreadcrumb>
      </v-card-title>
      <v-card-text>
        <v-form >
          <v-text-field
              v-model="domain"
              hide-details
              type="text"
              label="Domain"
              suffix="/rest/partners/actions/authmember/"

          ></v-text-field>
          <v-text-field
              v-model="client_id"
              hide-details
              type="text"
              label="Client Id"
          ></v-text-field>
          <v-text-field
              v-model="secret"
              hide-details
              type="text"
              label="Secret"
          ></v-text-field>
          <v-text-field
              v-model="person_id"
              hide-details
              type="number"
              label="Person Id"
          ></v-text-field>
          <v-text-field
              v-model="basket_id"
              hide-details
              type="text"
              label="Basket Id (optional)"
          ></v-text-field>
          <v-btn
              right
              :loading="loading"
              :disabled="loading"
              @click="login"
          >
            Log in
          </v-btn>
        </v-form>

      </v-card-text>
    </v-card>



  </v-layout>
</template>

<script>

import TenantBreadcrumb from "@/components/TenantBreadcrumb";
export default {
  components: {TenantBreadcrumb},
  data: () => ({
    secret: 'mysecret',
    domain: 'http://coursepro.local',
    client_id: 1,
    person_id: 301,
    basket_id: '',
    loading: false,
    tenant: {
      tenant_name: 'Loading',
      coursepro_tenant: {},
      _id: null
    },
  }),
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;
    });
  },
  methods: {
    login: function()
    {
      let formData = new FormData();
      formData.append('secret', this.secret);
      formData.append('domain', this.domain);
      formData.append('client_id', this.client_id);
      formData.append('person_id', this.person_id);

      let url = process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/leisurehub';
      if (this.basket_id !== '') {
        url += '?basket_id='+ this.basket_id;
      }
      this.$http.post(
          url,
          formData
      ).then(response => {
        let authA = response.data.authAddress;
        if (typeof authA == 'string' || authA instanceof String) {
            window.location.href = authA;
        } else {
          this.$toast.warning('Invalid authAddress '+authA);
        }
      }).catch(reason => {
        console.warn(reason);
        this.$toast.error('Invalid login');
      });
    }
  },
  name: "leisurehubsandbox"
}
</script>

<style scoped>

</style>
