<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        <TenantBreadcrumb
          v-bind:name="tenant.tenant_name"
          sub="Cron"
          v-bind:timestamp="cacheTime"
          >
        </TenantBreadcrumb>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="crondata"
          sort-by="task"
          class="elevation-1 config-table"
          fixed-header
          :loading="loading"
      >
        <template v-slot:item.schedule="{ item }">
          <CronSchedule
          v-bind:schedule="item.restrictions"
          v-bind:execution="item.execution"
          ></CronSchedule>
        </template>
        <template v-slot:item.last24="{ item }">
          <CronLast24
              v-bind:logs="item.logs"></CronLast24>
        </template>


      </v-data-table>



    </v-card>



  </v-layout>
</template>

<script>

import TenantBreadcrumb from "@/components/TenantBreadcrumb";
import CronSchedule from "@/components/CronSchedule";
import CronLast24 from "@/components/CronLast24";
export default {
  components: {TenantBreadcrumb, CronSchedule, CronLast24},
  data: () => ({
    loading: true,
    tenant: {
      tenant_name: 'Loading',
      coursepro_tenant: {},
      _id: null
    },
    crondata: [],
    cacheTime: null,
    headers: [

      { text: 'Group', value: 'group.name', sortable: true },
      { text: 'Task', value: 'taskName', sortable: true },
      { text: 'Schedule', value: 'schedule', sortable: false },
      {text: 'Status', value: 'state.status_text'},
      {text: 'Last Execution', value: 'state.finished_fuzzy', sortable: false },
      {text: 'Last 24hrs', value: 'last24', sortable: false  },
    ],
    logDialog:false
  }),
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/cron').then(response => {
        this.crondata =  response.data.tasks;
        this.cacheTime = response.data.created;
        this.loading = false;
      });
    });
  },
  name: "tenantcron"
}
</script>

<style scoped>

</style>
