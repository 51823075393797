<template>
  <div>
    <v-btn
        :color="buttonColor"
        :loading="isSelecting"
        @click="onButtonClick"
    >
      <v-icon left>
        {{ $props.icon }}
      </v-icon>
      {{ buttonText }}
    </v-btn>
    <form
        ref="uploaderForm"
        :action="formAction"
        method="post">


      <input
          ref="uploader"
          class="d-none"
          type="file"
          :accept="formAccepts"
          @change="onFileChanged"
      >
    </form>


  </div>


</template>

<script>

export default {
  name: 'FileUpload',
  data () {
    return {
      selectedFile: null,
      isSelecting: false,
    }
  },
  props: {
    text: {
      type: String,
      default: 'Upload File',
    },
    color: {
      type: String,
      default: 'blue darken-2',
    },
    icon: {
      type: String,
      default: 'mdi-cloud-upload-outline',
    },
    target: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: '*',
    },
    method: { type: Function },
  },
  computed: {
    buttonText() {
      if (this.selectedFile) {
        return this.selectedFile.name;
      } else {
        return this.$props.text;
      }
    },
    buttonColor() {
      return this.$props.color;
    },
    formAccepts() {
      return this.$props.accept;
    },
    formAction() {
      return this.$props.action;
    }

  },
  methods: {
    reset() {
      this.selectedFile = null;
      this.isSelecting = false;
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })
      this.$refs.uploader.click();

    },
    onFileChanged(e) {

      this.selectedFile = e.target.files[0];
      let formData = new FormData();
      formData.append('upload', this.selectedFile);
      let target = this.$props.target
      if (!target.startsWith('http')) {
        target = process.env.VUE_APP_CONTROL_API_URL + target;
      }
      this.$http.post(
          target,
          formData
      ).then(response => {
        this.selectedFile = null;
      //  console.log(response);
        this.$emit('callback', response);
        this.reset();
     //   this.$emit(this.$props.emit, response);
      });
    },

  }
}
</script>

<style lang="sass">

</style>
