<template>
  <div class="service-strip">
    <div
        v-for="(service, index) in activeServices"
        :key="index"
        class="service-block"
        :class="service.color"
        :style="{ width: '16px', color: service.textColor }"
        :title="service.tooltip"
    >
    <span class="service-label">{{ service.label }}</span>
  </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceStrip',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeServices() {
      const services = [];
      if (this.item.coursepro_tenant)
        services.push({ color: 'orange', label: 'C', tooltip: 'CoursePro', textColor: 'white' });
      if (this.item.homeportal_tenant)
        services.push({ color: 'green', label: 'H', tooltip: 'HomePortal', textColor: 'white' });
      if (this.item.homeportal2_tenant)
        services.push({ color: 'indigo', label: 'H', tooltip: 'HomePortal 2', textColor: 'white' });
      if (this.item.strive_tenant)
        services.push({ color: 'deep-orange', label: 'S', tooltip: 'Strive', textColor: 'white' });
      if (this.item.coachportal_tenant)
        services.push({ color: 'green', label: 'C', tooltip: 'Coach Portal', textColor: 'white' });
      if (this.item.schemeportal_tenant)
        services.push({ color: 'deep-purple', label: 'S', tooltip: 'Scheme Portal', textColor: 'white' });
      if (this.item.organisation_tenant)
        services.push({ color: 'red', label: 'O', tooltip: 'Organisation Portal', textColor: 'white' });
      if (this.item.parentportal_tenant)
        services.push({ color: 'blue', label: 'P', tooltip: 'Parent Portal', textColor: 'white' });
      if (this.item.commsportal_tenant)
        services.push({ color: 'blue-grey', label: 'C', tooltip: 'Comms Portal', textColor: 'white' });
      if (this.item.insight_tenant)
        services.push({ color: 'brown', label: 'I', tooltip: 'Insight', textColor: 'white' });
      if (this.item.coursepro_cron)
        services.push({ color: 'default', label: 'C', tooltip: 'CP Cron', textColor: 'black' });

      // Add more services as needed here
      // Example: if (this.item.another_service)
      // services.push({ color: 'blue', label: 'A', tooltip: 'Another Service' , textColor: 'white'});

      return services;
    }
  }
};
</script>

<style scoped>
.service-strip {
  display: flex;
  align-items: center;
  height: 16px; /* Adjust height as needed */
}

.service-block {
  height: 100%; /* Make sure the block fills the height of the strip */
  position: relative; /* For absolute positioning of the label */
}

.service-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  font-weight: bold;
  cursor: default;
}

.service-block.orange {
  background-color: orange;
}

.service-block.green {
  background-color: green;
}

.service-block.indigo {
  background-color: indigo;
}

.service-block.deep-purple {
  background-color: #7e57c2;
}

.service-block.deep-orange {
  background-color: deep-orange;
}

.service-block.blue-grey {
  background-color: blue-grey;
}

.service-block.brown {
  background-color: brown;
}

.service-block.red {
  background-color: red;
}
.service-block.default {
  background-color: grey; /* Adjust for 'default' or inactive */
}

/* Add additional service colors as needed */
</style>
