<template>
    <v-layout child-flex>
    <v-card>
        <v-card-title>
            Self hosted licence
        </v-card-title>
      <v-data-table
          :headers="headers"
          :items="config"
          sort-by="key"
          class="elevation-1 config-table"
          fixed-header
      >

<!--        <template v-slot:item.value="{ item }">-->
<!--          <span-->
<!--              @dblclick="editItem(item)"-->
<!--          >{{ truncateValue(item.value) }}</span>-->
<!--        </template>-->

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          No data
        </template>

        <template v-slot:item.type="{ item }">
          <v-avatar color="deep-orange" size="24" v-if="item.type=='1'">
            <span title="Known Constant" class="white--text">KC</span>
          </v-avatar>
          <v-avatar color="white" size="24" v-if="item.type=='2'">
            <span title="Unknown Constant" class="deep-orange--text">UC</span>
          </v-avatar>
          <v-avatar color="teal" size="24" v-if="item.type=='3'">
            <span title="Single-line Variable" class="white--text">SV</span>
          </v-avatar>
          <v-avatar color="deep-purple" size="24" v-if="item.type=='4'">
            <span title="Multi-line Variable" class="white--text">MV</span>
          </v-avatar>
          <v-avatar color="indigo" size="24" v-if="item.type=='5'">
            <span title="Code Block" class="white--text">CB</span>
          </v-avatar>
        </template>
      </v-data-table>
<v-card-text>
</v-card-text>
      <v-card-actions>
        <FileUpload
        text="Upload Licence"
        target="licence/decode"
        @callback="uploadLicence"
        >
        </FileUpload>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="exportLicence" >Export Licence</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                @click.stop="validateKey={};dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="editedIndex === -1">
                  <v-select
                      :items="types"
                      v-model="editedItem.type"
                      label="Type"
                      dense
                  ></v-select>
                </v-row>

                <v-row>
                  <v-combobox
                      v-if="editedItem.type === '1' || editedItem.type === '2'"
                      :items="knownKeys"
                      color="white"
                      label="Name"
                      v-model="editedItem.key"
                      v-on:change="changeKey"
                  ></v-combobox>
                  <v-text-field v-else v-model="editedItem.key" label="Name"
                                :readonly="editedItem.type==='1'"></v-text-field>
                  <v-item-group
                      v-for="(error,i) in keyErrors"
                      :key="i">
                    <v-alert type="warning">
                      {{ error }}
                    </v-alert>
                  </v-item-group>

                </v-row>
                <v-row
                    v-if="validateKey.description">

                  <v-alert
                      dense
                  >
                    {{ validateKey.description }}
                  </v-alert>
                </v-row>
                <v-row>
                  <v-textarea
                      v-if="getValueType() === 'textarea'"
                      v-model="editedItem.value"
                      filled
                      label="Value"
                      auto-grow
                  ></v-textarea>
                  <v-select
                      v-else-if="getValueType() === 'select'"
                      :items="validateKey.options"
                      item-text="display_name"
                      item-value="value"
                      v-model="editedItem.value"
                      label="Value"
                      dense
                  ></v-select>
                  <v-text-field v-else v-model="editedItem.value" label="Value"></v-text-field>


                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
    </v-layout>
</template>





<script>
    import FileUpload from "@/components/FileUpload";
    import TenantConfigBase from "@/components/TenantConfigBase";
    export default {
      extends: TenantConfigBase,
      components: {FileUpload},
      data: () => ({
              haltBubble: true,
              defaultButtonText: 'Upload licence',
              selectedFile: null,
              isSelecting: false,
        configType: 'Licence',
        apiSuffix: 'licence',
              headers: [

                {text: 'Name', value: 'key', sortable: true, width: "25%"},
                {text: 'Type', value: 'type', filterable: false, width: "7%"},
                {text: 'Value', value: 'value'},
                {text: 'Actions', value: 'actions', sortable: false, width: "8%"},
              ],
              config: [
                {
                  type:1,
                  key: 'centres',
                  value: 'unlimited'
                },
                {
                  type:1,
                  key: 'client',
                  valueTemplate: '{{TENANT_NAME}}',
                  value: null

                },
                {
                  type:1,
                  key: 'courses',
                  value: 'unlimited'
                },
                {
                  type:1,
                  key: 'disable_terms',
                  value: 0
                },
                {
                  type:1,
                  key: 'distributor',
                  value: 'cap2'
                },
                {
                  type:1,
                  key: 'expires',
                  value: ''
                },
                {
                  type:1,
                  key: 'product',
                  value: 'coursepro'
                },

              ]
      }),
      computed: {
        buttonText() {
          return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
        },
      },
      mounted() {
        if (this.apiSuffix) {
          this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "keys/" + this.apiSuffix).then(response => {
            this.keys = response.data.keys;
            this.knownKeys = this.keys.map(a => a.key_name);
          });
        }
      },
      methods: {
        b64toBlob(b64Data, contentType='', sliceSize=512) {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, {type: contentType});
          return blob;
        },
        uploadLicence(response) {
          if (response.data.valid) {
            this.$toast.success('Licence imported');
            this.config = response.data.licence;
          } else {
            this.$toast.error('Licence failed to import');
          }
        },
        exportLicence() {

          this.$dialog
              .prompt({
                title: "Choose a filename",
              }, {
                promptHelp: ''
              })
              .then(dialog => {
                if (dialog.data) {
                  this.$http.post(
                      process.env.VUE_APP_CONTROL_API_URL + 'licence/encode',
                      {
                        licence: this.config,
                        filename: dialog.data
                      }
                  ).then(response => {
                    // let blob = new Blob([response.data.binary], { type: 'application/octet-stream' });
                    let blob = this.b64toBlob(response.data.binary, 'application/octet-stream'  );
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = response.data.filename;
                    link.click();
                    console.log(response);
                  });
                }
              })
              .catch(() => {
                // Triggered when dialog is dismissed by user
              });
        }
      },
      name: "tools-list"
    }

</script>

<style scoped>

</style>
