import Axios from 'axios'

const apiUrl = process.env.VUE_APP_CONTROL_API_URL.replace(/\/$/, '');

const http = Axios;
http.defaults.withCredentials = true;
http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  });

http.interceptors.response.use((response) => {
  return response
},
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log('Refreshing token');
      return http.post(`${apiUrl}/authenticate/refresh`,
        {
          "refresh_token": localStorage.getItem('refreshToken')
        })
        .then(res => {
          if (res.status === 201) {
            // 1) put token to LocalStorage
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('username', res.data.username)

            // 2) Change Authorization header
            http.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

            // 3) return originalRequest object with Axios.
            return http(originalRequest);
          }
        })
    }
    // return Error object with Promise
    return Promise.reject(error);
  });

export { apiUrl, http };
