<script>
const hash = require('object-hash');
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'HomePortal',
    hashIndex: 2,
    apiSuffix: 'homeportal',
    config: []
  }),
  computed: {
    contextButtons() { return [
      {
        name: 'Generate Cache',
        id: 1,
        method: this.getApiCache
      }
    ]}
  },
  name: "tenant-homeportal",
  methods: {
    getApiCache: function(){
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/homeportal/cache').then(response => {
        let credentials = response.data;
        let updated = false;
        this.config.forEach(function(row, i) {
         if (row.key == '$cachedConfig') {
            row.value = credentials;
            this.config[i] = row;
            updated = true;
         }
        }, this);

        if (!updated) {
          this.config.push(
              {
                key: '$cachedConfig',
                type: 3,
                tenant: this.tenant.id,
                value: credentials
              }
          );
        }
        this.configHasChanges = (hash(this.config) !== this.configHash);

      });
    }
  }
}
</script>
