<template>
<div>
  <v-chip
      class="ma-2"
      color="green"
      text-color="white"
      v-if="getEnd().length"
      @click="logDetail('end')"
  >
    <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
    {{ getEnd().length }}
  </v-chip>

  <v-chip
      class="ma-2"
      color="red"
      text-color="white"
      v-if="getFail().length"
      @click="logDetail('fail')"
  >
    <v-icon>mdi-alert-circle</v-icon>
    {{ getFail().length }}

  </v-chip>

  <v-chip
      class="ma-2"
      color="gray"
      text-color="white"
      v-if="getDead().length"
      @click="logDetail('dead')"
  >
    <v-icon>mdi-skull-crossbones</v-icon>
    {{getDead().length}}
  </v-chip>
  <v-dialog
      v-model="logDialog"
      scrollable
      max-width="80%"
  >
    <v-card>
      <v-data-table
          :headers="logDetailHeaders"
          :items="logDetailData"
          sort-by="timestamp"
          class="elevation-1 config-table"
          fixed-header
      >
        <template v-slot:item.detail="{ item }">
          <span v-if="item.context.context"
              :title="item.context.context.message">{{ truncateVMsg(item.context.context.message)}}</span>
        </template>
        <template v-slot:item.timestamp="{ item }">
          <span>{{ item.datetime.date.substring(0,19)}}</span>
        </template>
        <template v-slot:item.duration="{ item }">
          <span>{{ secsToString(item.context.e2e.task_duration_in_seconds)}}</span>
        </template>

      </v-data-table>

    </v-card>
  </v-dialog>
</div>


 </template>

<script>
export default {
  name: 'CronLast24',
  data () {
    return {

      logDialog: false,
      logDetailData: [],
      logDetailHeaders: [
        { text: 'Date', value: 'timestamp', sortable: true },
        { text: 'Msg', value: 'message', sortable: true },
        { text: 'Detail', value: 'detail', sortable: true },
        { text: 'Duration', value: 'duration', sortable: true },

      ],

    }
  },
  props: {
    logs: {
      type: Array,
    },
  },
  methods: {
    secsToString(d) {
      d = Number(d);
      if (d < 10) {
        return Math.round(d * 1000) + ' ms';
      }
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
      return h.toString().padStart(2,'0') + ":" + m.toString().padStart(2,'0') + ":" + s.toString().padStart(2,'0');
    },
    truncateVMsg(str) {
      let l = 50;
      return (str.length > l) ? str.substr(0, l-3) + '...' : str;
    },
    logDetail(type) {
      if (type === 'end') {
        this.logDetailData = this.getEnd();
        this.logDialog = true;
      } else if (type === 'fail') {
        this.logDetailData = this.getFail();
        console.log(this.getFail());
        this.logDialog = true;
      } else if (type === 'dead') {
        this.logDetailData = this.getDead();
        this.logDialog = true;
      }
    },
    getEnd() {
      return this.$props.logs.filter(function(item){
        return 'task_end' === item.context.status;
      }).reverse();
    },
    getFail() {
      return this.$props.logs.filter(function(item){
        return 'task_fail' === item.context.status;
      }).reverse()
    },
    getDead() {
      return this.$props.logs.filter(function(item){
        return 'task_dead' === item.context.status;
      }).reverse()
    },
  },
}
</script>

<style lang="sass">

</style>
