<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        <TenantBreadcrumb
            v-if="tenant"
            v-bind:name="tenant.tenant_name"
            :sub="subName()"
        ></TenantBreadcrumb>

        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="config"
          :search="search"
          sort-by="key"
          class="elevation-1 config-table"
          fixed-header
          :loading="loading"
          :item-class="getRowClass"
      >
        <template v-slot:item.key="{ item }">
          <v-icon  v-if="item.db_only" class="mr-2 red--text" title="Tenant Database setting">mdi-database-alert</v-icon>
          <v-icon  v-if="!item.db_only && item.db_value && (item.db_value !== item.value)" class="mr-2" title="Tenant Database setting">mdi-database</v-icon>
          <span >{{ item.key }}</span>
        </template>

        <template v-slot:item.value="{ item }">
          <span
                        v-if="item.db_only"
                    >{{ truncateValue(item.db_value) }}</span>
          <span v-else
              @dblclick="editItem(item)"
          >{{ truncateValue(item.value) }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="!item.db_only" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon v-if="!item.db_only" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="injectDefault">Inject Default Template</v-btn>
        </template>

        <template v-slot:item.type="{ item }">
          <v-avatar color="deep-orange" size="24" v-if="item.type=='1'">
            <span title="Known Constant" class="white--text">KC</span>
          </v-avatar>
          <v-avatar color="white" size="24" v-if="item.type=='2'">
            <span title="Unknown Constant" class="deep-orange--text">UC</span>
          </v-avatar>
          <v-avatar color="teal" size="24" v-if="item.type=='3'">
            <span title="Single-line Variable" class="white--text">SV</span>
          </v-avatar>
          <v-avatar color="deep-purple" size="24" v-if="item.type=='4'">
            <span title="Multi-line Variable" class="white--text">MV</span>
          </v-avatar>
          <v-avatar color="indigo" size="24" v-if="item.type=='5'">
            <span title="Code Block" class="white--text">CB</span>
          </v-avatar>
        </template>
      </v-data-table>


      <v-card-actions>
        <v-btn color="secondary" @click="initialize">Reset</v-btn>

        <div v-for="cb in contextButtons" :key="cb.id">
          <v-btn color="secondary" @click="cb.method">
            {{ cb.name }}
          </v-btn>
        </div>

        <Download
            :download-data="config"
            v-if="config"
            class="ma-2"
            file-type="json"
            :file-name=jsonFilename()
            color="secondary"
            button-text="Download JSON"
        />


        <v-spacer></v-spacer>
        <v-btn
            :loading="commit"
            :disabled="commit || configHasChanges"
            v-if="tenantConfigHash !== tenantCurrentHash"
            :title="`${configType} config is different from the one available to servers`"
            @click="commitConfig()"
        >Commit</v-btn>
        <v-btn color="primary" @click="saveAll" :disabled="!configHasChanges">Save</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                @click.stop="validateKey={};dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="editedIndex === -1">
                  <v-select
                      :items="types"
                      v-model="editedItem.type"
                      label="Type"
                      dense
                  ></v-select>
                </v-row>

                <v-row>
                  <v-combobox
                      v-if="editedItem.type === '1' || editedItem.type === '2'"
                      :items="knownKeys"
                      color="white"
                      label="Name"
                      v-model="editedItem.key"
                      v-on:change="changeKey"
                  ></v-combobox>
                  <v-text-field v-else v-model="editedItem.key" label="Name"
                                :readonly="editedItem.type==='1'"></v-text-field>
                  <v-item-group
                      v-for="(error,i) in keyErrors"
                      :key="i">
                    <v-alert type="warning">
                      {{ error }}
                    </v-alert>
                  </v-item-group>

                </v-row>
                <v-row
                    v-if="validateKey.description">

                  <v-alert
                      dense
                  >
                    {{ validateKey.description }}
                  </v-alert>
                </v-row>
                <v-row>
                  <v-textarea
                      v-if="getValueType() === 'textarea'"
                      v-model="editedItem.value"
                      filled
                      label="Value"
                      auto-grow
                  ></v-textarea>
                  <v-select
                      v-else-if="getValueType() === 'select'"
                      :items="validateKey.options"
                      item-text="display_name"
                      item-value="value"
                      v-model="editedItem.value"
                      label="Value"
                      dense
                  ></v-select>
                  <v-text-field v-else v-model="editedItem.value" label="Value"></v-text-field>


                </v-row>

                <v-row
                v-if="editedItem.db_value">
                  <v-alert
                      type="warning"
                  >
                    This value is overrides the tenant's database value of <strong>{{ editedItem.db_value}}</strong>
                  </v-alert>
                </v-row>

                <v-row>
                  <v-col cols="8" class="text-right pa-0">
                    Active
                  </v-col>
                  <v-col cols="4" class="text-left pa-0">
                    <v-switch
                        class="pa-0 mt-0 ml-4"
                        dense
                        v-model="editedItem.state"
                    ></v-switch>
                  </v-col>
                </v-row>



              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>

    </v-card>

  </v-layout>
</template>


<script>

const hash = require('object-hash');
const diff = require('diff-arrays-of-objects');
import Download from 'json-data-convert-file/src/components/Download';
import TenantBreadcrumb from "@/components/TenantBreadcrumb";
import Tenant from '@/services/tenant';

export default {
  components: {Download, TenantBreadcrumb},
  data: () => ({
    haltBubble: false,
    configType: '',
    hashIndex: 1,
    defaultConfig: [],
    apiSuffix: '',
    search: '',
    types: [
      {'text': 'Constant', value: '2'},
      {'text': 'Single line variable', value: '3'},
      {'text': 'Multiline variable', value: '4'},
      {'text': 'Code block (one per config please)', value: '5'}
    ],
    editedIndex: -1,
    editedItem: {
      key: '',
      value: '',
      type: "2",
      state: 1,
    },
    defaultItem: {
      key: '',
      value: '',
      type: "2",
      state: 1,
    },
    dialog: false,
    configHasChanges: false,
    headers: [

      {text: 'Name', value: 'key', sortable: true, width: "25%"},
      {text: 'Type', value: 'type', filterable: false, width: "7%"},
      {text: 'Value', value: 'value'},
      {text: 'Actions', value: 'actions', sortable: false, width: "8%"},
    ],
    loading: true,
    tenant: {
      'tenant_name': 'Loading',
      '_id': null
    },
    config: [],
    keys: [],
    knownKeys: [],
    validateKey: {},
    keyErrors: [],
    boolSelect: [
      {
        display_name: 'True',
        value: "true"
      },
      {
        display_name: 'False',
        value: "false"
      },
    ],
    commit: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    contextButtons() {
      return [];
    },
    tenantConfigHash() {
      if (this.tenant && this.tenant.config_hashes) {
        return this.tenant.config_hashes[this.hashIndex] || null;
      }
      return null;
    },
    tenantCurrentHash() {
      if (this.tenant && this.tenant.current_hashes) {
        return this.tenant.current_hashes[this.hashIndex] || null;
      }
      return null;
    },
  },
  async mounted() {
    if (!this.haltBubble) {
      await this.getTenant()
      this.initialize();
    }
  },
  methods: {
    async getTenant() {
      const response = await this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id);
      this.tenant = response.data.tenant;
    },
    injectDefault() {
      this.defaultConfig.forEach(function (row) {
        if (row.valueTemplate) {
          row.value = row.valueTemplate.replace('{{TENANT_NAME}}', this.tenant.tenant_name);
        }
        this.config.push(row);
      }, this);
      this.configHasChanges = (hash(this.config) !== this.configHash);
    },
    getRowClass(item) {
      return item.state === 0 ? 'muted-row' : '';
    },
    subName() {
      return this.configType + ' Config';
    },
    truncateValue(str) {
      if (str) {
        let l = 300;
        return (str.length > l) ? str.substr(0, l - 3) + '...' : str;
      }
      return '';
    },
    changeKey(a) {
      this.validateKey = {};
      if (this.editedItem.type === "1" || this.editedItem.type === "2") {
        let idx = this.keys.map(a => a.key_name).indexOf(a)
        if (idx > -1) {
          this.setValidateKey({
                key_meta: this.keys[idx]
              }
          );
        }
      }
    },
    getValueType() {
      if (this.editedItem.type === "4" || this.editedItem.type === "5") {
        return 'textarea';
      }
      if (this.validateKey.options) {
        return 'select'
      }
      return 'text';
    },
    jsonFilename() {
      return this.tenant.tenant_name + '-' + this.apiSuffix + '-config';
    },
    initialize() {
      this.loading = true;
      if (this.apiSuffix) {
        this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "keys/" + this.apiSuffix).then(response => {
          this.keys = response.data.keys;
          this.knownKeys = this.keys.map(a => a.key_name);
        });

        this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + this.apiSuffix).then(response => {
          this.config = response.data;
          this.configHash = hash(this.config);
          this.originalConfig = this.config.map(item => {
            return {...item}
          }).filter((obj) => obj.db_only !== true);
          this.configHasChanges = false;
          this.loading = false;
        });
      }
    },
    setValidateKey: function (item) {
      if (item.key_meta) {
        if (item.key_meta.type === 'bool') {
          item.key_meta.options = this.boolSelect;
        }
        this.validateKey = item.key_meta;
      } else {
        this.validateKey = {}
      }
      this.keyErrors = [];
      if (this.validateKey.dependencies) {
        Object.values(this.validateKey.dependencies).forEach(function (dep) {
          let match = this.getConfigByKey(dep.name);
          let matchInx = dep.values.indexOf(match.value);
          if (matchInx < 0) {
            this.keyErrors.push(this.validateKey.key_name + ' is not compatible with setting ' + dep.name + '=' + match.value);
          }
        }, this);
      }
      if (this.validateKey.depends) {
        Object.values(this.validateKey.depends).forEach(function (dep) {
          let match = this.getConfigByKey(dep.depends_on_name);
          let current = false;
          if (match.key_meta && match.key_meta.type === 'bool') {
            if (match.value === 'true') {
              current = true;
            }
          }
          if (!current) {
            this.keyErrors.push(this.validateKey.key_name + ' requires ' + dep.depends_on_name + ' to be true (Currently ' + match.value + ')');
          }
        }, this);
      }
    },
    getConfigByKey: function (key) {
      let matches = this.config.filter(function (item) {
        return key === item.key;
      }, key);
      if (matches.length) {
        return matches.pop();
      }
      return {}
    },
    editItem(item) {

      this.editedIndex = this.config.indexOf(item)

      this.editedItem = Object.assign({}, item)
      this.setValidateKey(item)
      this.dialog = true
    },
    deleteItem(item) {
      const index = this.config.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.config.splice(index, 1)
      this.configHasChanges = (hash(this.config) !== this.configHash);
    },
    save() {
      if (this.editedItem.type === "2" && this.keys.map(a => a.key_name).indexOf(this.editedItem.key) > -1) {
        this.editedItem.type = "1";
      }
      if (this.editedItem.state === false) {
        this.editedItem.state = 0;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.config[this.editedIndex], this.editedItem)
      } else {
        //check it doesnt exist already if it is a known constant
        if (this.editedItem.type === "1" && this.config.map(a => a.key).indexOf(this.editedItem.key) > -1) {
          this.$toast.warning(this.editedItem.key + ' is a known constant and already exists.');
        } else {
          this.config.push(this.editedItem)
        }
      }
      this.configHasChanges = (hash(this.config) !== this.configHash);
      this.close()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveAll() {
      let checkConfig =  this.config.filter((obj) => obj.db_only !== true);
      const diffObj = diff(this.originalConfig, checkConfig);
      delete diffObj.same;
      let response = await this.$http.post(
          process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + this.apiSuffix,
          diffObj
      );

      if (response.data.warnings && response.data.warnings.length) {
        response.data.warnings.forEach(function (item) {
          this.$toast.error(item);
        }, this);
      }
      await this.getTenant();
      await this.initialize();
      this.$toast.success('Config successfully saved');

      response = await this.$http.get(
        process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/' + this.apiSuffix + '/validate',
      );
      if (!response.data.valid) {
        response.data.errors.forEach(row => this.$toast.error(row), this);
        response.data.warnings.forEach(row => this.$toast.warning(row), this);
      }
    },
    async commitConfig() {
      this.commit = true;
      const type = this.configType.toLowerCase();
      const response = await Tenant.commitConfig(this.$route.params.id, type);
      this.commit = false;
      if (response.data.type && response.data.hash) {
        this.tenant.config_hashes[response.data.type] = response.data.hash;
        this.$toast.success('Config successfully exported');
      } else {
        this.$toast.error(response.data);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.configHasChanges) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next();
    }
  },
  name: "tenant-config-base"
}
</script>

<style>
.config-table table {
  table-layout: fixed;
}

.muted-row {
  opacity: 0.5; /* Adjust the value as needed */
}
</style>
