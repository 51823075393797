<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'CoursePro',
    hashIndex: 1,
    apiSuffix: 'coursepro',
    config: [],
  }),
  computed: {
    // contextButtons() { return [
    //   {
    //     name: 'Run db install',
    //     id: 1,
    //     method: this.runDbInstall
    //   }
    // ]}
  },
  // methods: {
  //   runDbInstall: function(){
  //     console.log('Send SNS to install db via control api');
  //     this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id + '/coursepro/install').then(response => {
  //       if (response.data.error) {
  //         this.$toast.error(response.data.error);
  //       } else {
  //         this.$toast.success('DB upgrade message sent successfully');
  //       }
  //
  //     });
  //   }
  // },
  name: "tenant-coursepro-config"
}
</script>
