<template>
    <v-layout child-flex>
    <v-card>
        <v-card-title>
            Databases
          <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="databases"
                :search="search"
                :items-per-page="10"
                class="elevation-1"
                :loading="loading"
        >
          <template v-slot:item.db_activity_data="{ item }">
            <SparkLine
                v-bind:data="JSON.parse(item.db_activity_data)"
            ></SparkLine>
          </template>
<!--          <template v-slot:item.group_name="{ item }">-->
<!--            <v-chip-->
<!--                class="ma-2"-->
<!--                color="blue"-->
<!--                v-if="item.group_name=='blue'"-->
<!--            >-->
<!--              {{item.group_name.toUpperCase()}}-->
<!--            </v-chip>-->
<!--            <v-chip-->
<!--                class="ma-2"-->
<!--                color="green"-->
<!--                v-else-if="item.group_name=='green'"-->
<!--            >-->
<!--              {{item.group_name.toUpperCase()}}-->
<!--            </v-chip>-->
<!--            <v-chip-->
<!--                class="ma-2"-->
<!--                v-else-->
<!--            >-->
<!--              {{item.group_name.toUpperCase()}}-->
<!--            </v-chip>-->

<!--          </template>-->
        </v-data-table>
    </v-card>
    </v-layout>
</template>





<script>
import SparkLine from "@/components/Sparkline";

    export default {
      components: {SparkLine},
        data () {
            return {
                search: '',
                headers: [
                  { text: 'Id', value: 'id' },
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                  { text: '# Tenants', value: 'tenant_count' },
                  { text: 'Load', value: 'db_activity_average' },
                  { text: '24hrs', value: 'db_activity_data' },
                  { text: 'Identifier', value: 'identifier' },
                  { text: 'Endpoint', value: 'endpoint' },
                ],
                loading: true,
              sparkline: false,
              databases: [],

            }
        },
        mounted() {

            this.$http.get(process.env.VUE_APP_CONTROL_API_URL +"database").then(response => {
                this.databases = response.data.databases
              this.loading = false;
            });
        },
        name: "database-list"
    }

</script>

<style scoped>

</style>
