<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'Commsportal',
    apiSuffix: 'commsportal',
    tenantDataKey: 'commsportal_tenant',
    urlKey: 'commsportal_url',
    generateUrlString: '.commsportal.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 9;
      }
      if (type === 'worker') {
        return 10;
      }
    },
  },
  name: "tenant-commsportal-service"
}
</script>
