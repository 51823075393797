
import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter);

import Dashboard from "./components/Dashboard";
import TenantList from "./components/TenantList";
import Tenant from "./components/Tenant";
import NotFound from "./components/NotFound";
import EnvironmentList from "./components/EnvironmentList";
import ToolsList from "./components/ToolstList";
import Landing from "./components/Landing";
import About from "./components/About";
import store from './store';
import Cron from "@/components/Cron";
import Debug from "@/components/Debug";
import TenantCourseproConfig from "@/components/TenantCourseproConfig";
import TenantHomeportalConfig from "@/components/TenantHomeportalConfig";
import TenantHomeportal2Config from "@/components/TenantHomeportal2Config";
import TenantLicenceConfig from "@/components/TenantLicenceConfig";
import TenantStriveConfig from "@/components/TenantStriveConfig";
import TenantInsightConfig from "@/components/TenantInsightConfig";
import TenantCommsportalConfig from "@/components/TenantCommsportalConfig";
import TenantCourseproService from "@/components/TenantCourseproService";
import TenantHomeportalService from "@/components/TenantHomeportalService";
import TenantHomeportal2Service from "@/components/TenantHomeportal2Service";
import TenantCourseproSystem from "@/components/TenantCourseproSystem";
import TenantCron from "@/components/TenantCron";
import ToolsSelfHostedLicence from "@/components/ToolsSelfHostedLicence";
import TenantSandbox from "@/components/TenantSandbox";
import ToolsImporter from "@/components/ToolsImporter";
import TenantRelations from "@/components/TenantRelations";
import Monitor from "@/components/Monitor";
import MonitorItem from "@/components/MonitorItem";
import ToolsMapper from "@/components/ToolsMapper";
import ToolsCreateRelease from '@/components/ToolsCreateRelease.vue';
import ToolsDeployRelease from "@/components/ToolsDeployRelease";
import CompetencyMapping from "@/components/CompetencyMapping";
import Val from "@/components/Val";
import LeisureHubSandbox from "@/components/LeisureHubSandbox";
import TenantCoachPortalService from "@/components/TenantCoachPortalService";
import TenantSchemePortalService from "@/components/TenantSchemePortalService";
import TenantOrganisationPortalService from "@/components/TenantOrganisationPortalService";
import TenantParentPortalService from "@/components/TenantParentPortalService";
import TenantStriveService from "@/components/TenantStriveService";
import TenantInsightService from "@/components/TenantInsightService";
import TenantCommsportalService from "@/components/TenantCommsportalService";
import ToolsSeedTenant from './components/ToolsSeedTenant.vue';
import DatabaseList from "@/components/DatabaseList.vue";
import ToolsUploadTranslations from "./components/ToolsUploadTranslations.vue";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "landing",
            component: Landing
        },
        {
            path: '/about',
            name: "about",
            component: About
        },
        {
            path: '/val',
            name: "Val",
            component: Val
        },
        {
            path: '/dashboard',
            name: "dashboard",
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/cron',
            name: "cron",
            component: Cron,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/monitor',
            name: "monitor",
            component: Monitor,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/monitor/:id',
            name: 'monitordetail',
            component: MonitorItem,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant',
            component: TenantList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id',
            name: 'tenant',
            component: Tenant,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/relations',
            name: 'relations',
            component: TenantRelations,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/coursepro',
            name: 'coursepro',
            component: TenantCourseproService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/coursepro/config',
            name: 'courseproconfig',
            component: TenantCourseproConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/coursepro/system',
            name: 'courseprosystem',
            component: TenantCourseproSystem,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/sandbox',
            name: 'legendsandbox',
            component: TenantSandbox,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/leisurehub',
            name: 'leisurehubsandbox',
            component: LeisureHubSandbox,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/homeportal',
            name: 'homeportal',
            component: TenantHomeportalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/homeportal/config',
            name: 'homeportalconfig',
            component: TenantHomeportalConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/homeportal2',
            name: 'homeportal2',
            component: TenantHomeportal2Service,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/coachportal',
            name: 'coachportal',
            component: TenantCoachPortalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/organisationportal',
            name: 'organisationportal',
            component: TenantOrganisationPortalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/parentportal',
            name: 'parentportal',
            component: TenantParentPortalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/schemeportal',
            name: 'schemeportal',
            component: TenantSchemePortalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/strive',
            name: 'strive',
            component: TenantStriveService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/insight',
            name: 'insight',
            component: TenantInsightService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/commsportal',
            name: 'commsportal',
            component: TenantCommsportalService,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/homeportal2/config',
            name: 'homeportal2config',
            component: TenantHomeportal2Config,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/licence/config',
            name: 'licenceconfig',
            component: TenantLicenceConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/strive/config',
            name: 'striveconfig',
            component: TenantStriveConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/insight/config',
            name: 'insightconfig',
            component: TenantInsightConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/commsportal/config',
            name: 'commsportalconfig',
            component: TenantCommsportalConfig,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tenant/:id/cron',
            name: 'tenantcron',
            component: TenantCron,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/environment',
            component: EnvironmentList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/database',
            component: DatabaseList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools',
            component: ToolsList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/licence',
            component: ToolsSelfHostedLicence,
            name: 'toolslicence',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/importer',
            component: ToolsImporter,
            name: 'toolsimporter',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/mapper',
            component: ToolsMapper,
            name: 'toolsmapper',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/competency-mapping/:hash',
            name: 'competency-mapping',
            component: CompetencyMapping,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/tools/create-release',
            name: 'tools-create-release',
            component: ToolsCreateRelease,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/deploy-release',
            name: 'tools-deploy-release',
            component: ToolsDeployRelease,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/seed-tenant',
            name: 'tools-seed-tenant',
            component: ToolsSeedTenant,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tools/upload-translations',
            name: 'tools-upload-translations',
            component: ToolsUploadTranslations,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/debug',
            name: 'debug',
            component: Debug,
        },
        {
            path: "*",
            component: NotFound
        }
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters["user/isLoggedIn"]) {
            next();
            return;
        }
        next('/');
    } else {
        next()
    }
})

export default router;

