<template>
  <v-chip
      v-bind="$attrs"
      class="ma-2"
      small
      :color="chipColor()"
  >
    <v-avatar left v-if="expiry !== null">
      <v-icon>{{ chipIcon() }}</v-icon>
    </v-avatar>
    {{ chipText() }}
  </v-chip>
</template>

<script>
export default {
  name: 'LicenceExpiry',
  props: {
    expiry: {
      type: String, // You can change this to Date if needed, or accept both with [String, Date]
      default: '',
    },
  },
  data() {
    return {
      days: undefined,
      state: undefined,
    };
  },
  mounted() {
    this.calculateState(); // Move calculation logic to a separate method
  },
  watch: {
    expiry: {
      immediate: true,
      handler() {
        this.calculateState(); // Recalculate state when expiry changes
      }
    }
  },

  methods: {
    calculateState() {
      if (this.expiry) {
        const expiryDate = new Date(this.expiry);
        const currentDate = new Date();

        if (isNaN(expiryDate.getTime())) {
          this.state = -2; // Invalid date state
          return;
        }

        this.days = (expiryDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);
        if (this.days < 0) {
          this.state = -1; // Expired
        } else if (this.days < 91) {
          this.state = 0; // Expiring soon
        } else {
          this.state = 1; // Valid
        }
      } else {
        this.state = 1; // Default state for non-expiring
      }
    },
    chipColor() {
      if (!this.expiry) {
        return 'success'; // Default color for no expiry
      }
      switch (this.state) {
        case -1:
          return 'error'; // Red for expired
        case 0:
          return 'warning'; // Yellow for expiring soon
        case 1:
          return 'success'; // Green for valid
        case -2:
          return 'warning'; // Yellow for invalid date
        default:
          return 'success';
      }
    },
    chipText() {
      if (!this.expiry) {
        return 'Infinity'; // Default text for non-expiring
      }
      switch (this.state) {
        case -1:
          return 'Expired';
        case 0:
          return `Expires in ${this.days.toFixed(0)} days`;
        case 1:
          return this.expiry; // Return the expiry date
        case -2:
          return 'Invalid date'; // Return text for invalid expiry
        default:
          return this.expiry;
      }
    },
    chipIcon() {
      if (!this.expiry) {
        return 'mdi-infinity'; // Default icon for non-expiring
      }
      switch (this.state) {
        case -1:
          return 'mdi-block-helper'; // Icon for expired
        case 0:
          return 'mdi-alert-circle'; // Icon for expiring soon
        case 1:
          return 'mdi-thumb-up'; // Icon for valid
        case -2:
          return 'mdi-alert-circle'; // Icon for invalid date
        default:
          return 'mdi-thumb-up'; // Default icon for valid
      }
    },
  },
};
</script>

<style scoped>
/* Add any necessary styling here */
</style>
