<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Tenants
        <v-spacer></v-spacer>
        <v-select
            :items="filters"
            :label="'Filter (' + (tenants.length - tenantsData.length) + ' items hidden)'"
            v-model="filter"
            item-text="text"
            item-value="value"
            @change="saveFilter"
        ></v-select>

        <span class="text-sm-body-2 ml-4">Show inactive</span> <v-switch class="pa-0 mt-4 ml-2" dense v-model="showInactive" @change="saveFilter"></v-switch>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="tenantsData"
          :search="search"
          item-key="tenantsData._id"
          :items-per-page="10"
          class="elevation-1"
          :loading="loading"
          @dblclick:row="handleRowDoubleClick"
          :disable-pagination="tenantsData.length < 11"
          :hide-default-footer="tenantsData.length < 11"
      >
        <template v-slot:no-data>

            No results found. ({{ tenants.length }} items filtered)

        </template>
        <template v-slot:item.region="{ item }">
          <span v-if="item.region.startsWith('us')" title="us-east-1"><UsFlag title="us-east-1"/></span>
          <span v-else-if="item.region.startsWith('eu')" title="eu-west-1"><EuFlag title="eu-west-1"/></span>
          <span v-else-if="item.region.startsWith('ap')" title="ap-sydney-1"><AuFlag title="ap-sydney-1"/></span>
        </template>
        <template v-slot:item.services="{ item }">
          <ServiceStrip :item="item"/>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
              class="ma-2"
              color="green"
              small
              v-if="item.tenant_status=='1'"
          >
            Yes
          </v-chip>
          <v-chip
              class="ma-2"
              color="red"
              small
              v-if="item.tenant_status=='0'"
          >
            No
          </v-chip>
        </template>
        <template v-slot:item.licence_expires="{ item }">
          <LicenceExpiry v-if="item.coursepro_tenant"
              v-bind:expiry="item.licence_expires"
          ></LicenceExpiry>
        </template>

        <template v-slot:item.total_members="{ item }">
            <span v-if="item.total_members">
              {{ item.total_members.toLocaleString() }}
            </span>
          <span v-else>
              n/a
            </span>
        </template>

        <template v-slot:item.db_host_label="{ item }">
          <span v-if="(item.coursepro_db_host_label) || (item.insight_db_host_label)">
        <v-chip v-if="item.coursepro_db_host_label" class="ma-0" color="orange" x-small label text-color="black">
                {{ item.coursepro_db_host_label }}
        </v-chip>
              <v-chip v-if="item.insight_db_host_label" class="ma-0" color="brown" x-small label text-color="white">
                {{ item.insight_db_host_label }}
        </v-chip>
          </span>
        </template>

        <template v-slot:item.coursepro_bridge="{ item }">
          <Bridge v-if="item.coursepro_tenant"
              v-bind:bridge="item.coursepro_bridge"
              v-bind:version="item.coursepro_version"
              :showSandboxLink="false"
          />
        </template>
        <template v-slot:item.deployment_group="{ item }">
          <v-chip
              class="ma-2"
              color="red darken-4"
              v-if="item.deployment_group=='dev'"
          >
            DEV
          </v-chip>
          <v-chip
              class="ma-2"
              color="yellow darken-4"
              small
              v-if="item.deployment_group=='staging'"
          >
            STAGE
          </v-chip>
          <v-chip
              class="ma-2"
              color="green darken-4"
              small
              v-else-if="item.deployment_group=='production'"
          >
            PROD
          </v-chip>
          <v-chip
              class="ma-2"
              small
              v-else-if="item.deployment_group"
          >
            {{ item.deployment_group.toUpperCase() }}
          </v-chip>

        </template>
        <template v-slot:item.actions="{ item }">
          <v-chip
              class="ma-2"
              :to="{ name: 'tenant', params: { id: item._id }}"
              color="primary"
          >
            View
          </v-chip>
        </template>
      </v-data-table>


      <v-card-actions>
        <v-btn @click="tenantListCacheBust()">Refresh</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                absolute
                dark
                fab
                bottom
                right
                color="pink"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add tenant</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row>
                  <v-text-field v-model="newTenant.tenant" label="Name"></v-text-field>
                </v-row>
                <v-row>
                  <v-select
                      :items="regions"
                      v-model="newTenant.region"
                      label="Region"
                      dense
                  ></v-select>
                </v-row>
                <v-row>
                  <v-select
                      :items="accounts"
                      v-model="newTenant.account"
                      label="Account"
                      dense
                  ></v-select>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>

    </v-card>
  </v-layout>
</template>


<script>
import LicenceExpiry from "@/components/LicenceExpiry";
import Bridge from "@/components/Bridge";
import UsFlag from '@/components/flags/UsFlag.vue';
import EuFlag from '@/components/flags/EuFlag.vue';
import AuFlag from '@/components/flags/AuFlag.vue';
import ServiceStrip from "@/components/display/ServiceStrip.vue";


export default {
  components: {ServiceStrip, LicenceExpiry, Bridge, UsFlag, EuFlag, AuFlag},
  data() {
    return {
      filter: null,
      filters: [
        {text: 'No filter', value: null},
      ],
      showInactive: false,
      search: '',
      headers: [
        {text: 'Region', value: 'region'},
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'tenant_name',
        },
        {text: 'Account', value: 'account_name'},
        {text: 'Active', value: 'status'},
        {text: 'Services', value: 'services', sortable: false},

        {text: 'Deployment', value: 'deployment_group'},
        {text: 'CoursePro Version', value: 'coursepro_version'},
        {text: 'DB Version', value: 'db_version'},
        {text: 'DB Host Label', value: 'db_host_label'},
        {text: 'Bridge', value: 'coursepro_bridge'},
        {text: 'Licence', value: 'licence_expires'},
        {text: 'Members', value: 'total_members'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      regions: [],
      accounts: [],
      tenants: [],
      loading: true,
      dialog: false,
      newTenant: {
        tenant: '',
        region: 1,
        account: 1,
      },
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant").then(response => {

      this.tenants = response.data.tenants;
      this.makeFilters();
      this.loading = false;
      const savedState = JSON.parse(localStorage.getItem('tenantListFilter'));
      if (savedState) {
        this.filter = savedState.filter || null;
        this.showInactive = savedState.showInactive || false;
      }

    });
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "region").then(response => {
      this.regions = response.data.regions;
    });
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "accounts").then(response => {
      this.accounts = response.data.accounts;
    });



  },
  computed: {
    tenantsData() {
      if (this.filter || !this.showInactive) {
        //let parts = this.filter.split(':');
        let parts = this.filter ? this.filter.split(':') : [];
        return this.tenants.filter(function (item) {
          let conditionPasses = false;
          if (parts[0] === 'service') {
            conditionPasses = item[`${parts[1]}_tenant`] !== null;
          } else {
            conditionPasses = item[parts[0]] === parts[1];
          }

          if (this.showInactive) {
            return conditionPasses; // If showInactive is true, return based on the original condition
          }
          return conditionPasses && item.tenant_status === 1; // If showInactive is false, also check item.active
        }, this);

      }
      return this.tenants;
    }
  },
  methods: {
    handleRowDoubleClick(event, {item}) {
      this.$router.push({name: 'tenant', params: {id: item._id}});
    },
    normalizeMembers: function (total) {
      //console.log('Got '+total+ ' normal: '+ Math.floor(total/1000)/2);
      return parseFloat(Math.floor(total / 1000) / 2);
    },
    makeFilters: function () {
      const groupedFilters = [];

      // Add No Filter
      groupedFilters.push({ text: 'No filter', value: null });

      // Define groups
      const regions = [...new Set(this.tenants.map(a => a.region))].sort();
      const accounts = [...new Set(this.tenants.map(a => a.account_name))].sort();
      const groups = [...new Set(this.tenants.map(a => a.deployment_group))].sort();
      const versions = [...new Set(this.tenants.map(a => a.coursepro_version).filter(v => v))].sort();
      const dbs = [...new Set(this.tenants.map(a => a.db_version).filter(v => v))].sort();
      const dbLabels = [...new Set(this.tenants.map(a => a.coursepro_db_host_label).filter(v => v))].sort();
      const bridges = [...new Set(this.tenants.map(a => a.coursepro_bridge).filter(v => v))].sort();
      const cpEnvs = [...new Set(this.tenants.map(a => a.cpweb_name).filter(v => v))].sort();

      const services = [
        { text: 'CoursePro', value: 'coursepro' },
        { text: 'HomePortal2', value: 'homeportal2' },
        { text: 'Strive', value: 'strive' },
        { text: 'Organisation Portal', value: 'organisation' },
        { text: 'Parent Portal', value: 'parentportal' },
        { text: 'Comms Portal', value: 'commsportal' },
        { text: 'Insight', value: 'insight' },
    ];

      // Push grouped filters to the array
      if (regions.length) {
        groupedFilters.push({ text: 'Region', disabled: true });
        regions.forEach(value => {
          groupedFilters.push({ text: value, value: 'region:' + value });
        });
      }

      if (groups.length) {
        groupedFilters.push({ text: 'Group', disabled: true });
        groups.forEach(value => {
          groupedFilters.push({ text: value, value: 'deployment_group:' + value });
        });
      }

      if (accounts.length) {
        groupedFilters.push({ text: 'Account', disabled: true });
        accounts.forEach(value => {
          groupedFilters.push({ text: value, value: 'account_name:' + value });
        });
      }

      if (services.length) {
        groupedFilters.push({ text: 'Service', disabled: true });
        services.forEach(item => {
          groupedFilters.push({ text: item.text, value: 'service:' + item.value });
        });
      }

      if (versions.length) {
        groupedFilters.push({ text: 'Version', disabled: true });
        versions.forEach(value => {
          groupedFilters.push({ text: value, value: 'coursepro_version:' + value });
        });
      }

      if (dbs.length) {
        groupedFilters.push({ text: 'CP DB Version', disabled: true });
        dbs.forEach(value => {
          groupedFilters.push({ text: value, value: 'db_version:' + value });
        });
      }

      if (dbLabels.length) {
        groupedFilters.push({ text: 'CP DB Label', disabled: true });
        dbLabels.forEach(value => {
          groupedFilters.push({ text: value, value: 'coursepro_db_host_label:' + value });
        });
      }

      if (bridges.length) {
        groupedFilters.push({ text: 'CP Bridge', disabled: true });
        bridges.forEach(value => {
          groupedFilters.push({ text: value, value: 'coursepro_bridge:' + value });
        });
      }

      if (cpEnvs.length) {
        groupedFilters.push({ text: 'CP Env', disabled: true });
        cpEnvs.forEach(value => {
          groupedFilters.push({ text: value, value: 'cpweb_name:' + value });
        });
      }

      // Assign the final array to filters
      this.filters = groupedFilters;
    },
    saveFilter() {
      // Save both filter and showInactive to local storage
      const state = {
        filter: this.filter,
        showInactive: this.showInactive
      };
      localStorage.setItem('tenantListFilter', JSON.stringify(state));

    },

    oldmakeFilters: function () {
      this.filters = [
        {text: 'No filter', value: null},
      ];
      [...new Set(this.tenants.map(a => a.region))].forEach(value => {
        this.filters.push({
          text: 'Region: ' + value,
          value: 'region:' + value
        })
      });
      [...new Set(this.tenants.map(a => a.deployment_group))].forEach(value => {
        this.filters.push({
          text: 'Group: ' + value,
          value: 'deployment_group:' + value
        })
      });
      [...new Set(this.tenants.map(a => a.coursepro_version))].forEach(value => {
        this.filters.push({
          text: 'Version: ' + value,
          value: 'coursepro_version:' + value
        })
      });
      [...new Set(this.tenants.map(a => a.db_version))].forEach(value => {
        this.filters.push({
          text: 'DB: ' + value,
          value: 'db_version:' + value
        })
      });
      [...new Set(this.tenants.map(a => a.db_host_label))].forEach(value => {
        this.filters.push({
          text: 'DB Label: ' + value,
          value: 'db_host_label:' + value
        })
      });
      [...new Set(this.tenants.map(a => a.coursepro_bridge))].forEach(value => {
        this.filters.push({
          text: 'Bridge: ' + value,
          value: 'coursepro_bridge:' + value
        })
      });

      [...new Set(this.tenants.map(a => a.cpweb_name))].forEach(value => {
        this.filters.push({
          text: 'CP Env: ' + value,
          value: 'cpweb_name:' + value
        })
      });


    },
    tenantListCacheBust: function () {
      this.$http.get(
          process.env.VUE_APP_CONTROL_API_URL + "tenant/refresh"
      ).then(response => {
        console.log(response);
        this.loading = true
        this.tenants = [];
        this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant").then(response => {
          this.tenants = response.data.tenants;
          this.loading = false;
        });
      });
    },
    save() {
      this.$http.put(
          process.env.VUE_APP_CONTROL_API_URL + "tenant/",
          this.newTenant
      ).then(response => {
        this.$toast.success('Successfully created tenant `' + response.data.tenant + '`');
        this.$router.push('/tenant/' + response.data.id);
      })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              this.$toast.error(error.response.data.msg);
            }
          });
      this.close()
    },
    close() {
      this.dialog = false;
    },
  },
  name: "tenant-list"
}

</script>

<style scoped>
.flag-icon {
  width: 30px; /* Set the desired width */
  height: 20px; /* Set the desired height */
}
/* Style the label of the v-select */
.v-input__control {
  background-color: #f9f9f9; /* Example background */
}



</style>
