<template>
  <svg class="sparkline" :width="width" :height="height" :stroke-width="stroke" :style="{ stroke: getStrokeColor(), fill: getStrokeColor(true) }">
    <path class="sparkline--line" :d="shape" fill="none"></path>
    <path
      class="sparkline--fill"
      :d="[shape, fillEndPath].join(' ')"
      stroke="none"
    ></path>
  </svg>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      stroke: 1,
      width: 128,
      height: 40,
    };
  },
  methods: {
    getStrokeColor: function(fill=false)
    {
      if (!this.data || this.data.length === 0) {
        return fill ? 'rgba(0, 0, 0, 0.1)' : '#666666'; // Default gray for empty data
      }
      const highestPoint = Math.max.apply(null, this.data) + 1;
          if (highestPoint < 4) {
            return fill ? 'rgb(50, 168, 82, 0.3)' : '#32a852'; // Green
          } else if (highestPoint < 10) {
            return fill ? 'rgb(255, 255, 255, 0.3)' :  '#ffffff'; // White
          } else if (highestPoint < 20) {
            return fill ? 'rgb(230, 129, 14, 0.3)' :  '#e6810e'; // Orange
          } else {
            return fill ? 'rgb(245, 2, 43, 0.3)' :  '#f5022b'; // Red
          }
    }
  },
  computed: {
    shape() {
      const stroke = this.stroke;
      const width = this.width;
      const height = this.height - stroke * 2;
      const data = this.data || [];
      if (data.length === 0) {
        // Draw the default line at the bottom of the chart
        return `M 0 ${height} L ${width} ${height}`;
      }
      //const highestPoint = Math.max.apply(null, data) + 1;
      const highestPoint = Math.max(40, Math.max.apply(null, data) + 1);
      const coordinates = [];
      const totalPoints = this.data.length - 1;
      data.forEach((item, n) => {
        const x = (n / totalPoints) * width + stroke;
        const y = height - (item / highestPoint) * height + stroke;
        coordinates.push({ x, y });
      });
      if (!coordinates[0]) {
        return (
          "M 0 " +
          this.stroke +
          " L 0 " +
          this.stroke +
          " L " +
          this.width +
          " " +
          this.stroke
        );
      }
      const path = [];
      coordinates.forEach((point) =>
        path.push(["L", point.x, point.y].join(" "))
      );
     // console.warn(["M" + coordinates[0].x, coordinates[0].y, ...path].join(" "));
      return ["M" + coordinates[0].x, coordinates[0].y, ...path].join(" ");
    },
    fillEndPath() {
      return `V ${this.height} L 4 ${this.height} Z`;
    },
  },
};
</script>
<style>
svg {
  fill: rgba(31, 140, 235, 0.06);
  transition: all 1s ease-in-out;
}
svg path {
  box-sizing: border-box;
}
</style>
