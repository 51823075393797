<template>
  <v-chip
      v-if="chipText()"
      v-bind="$attrs"
      class="ma-2 bridge"
      small
      :color="chipColor()"
      v-on="showSandboxLink ? { click: handleChipClick } : {}"
  >
    <v-avatar left v-if="bridge !== null">
<!--      <img :src="chipIcon()" alt="Bridge Icon" />-->
      <v-icon>mdi-bridge</v-icon>
    </v-avatar>
    <span>
      {{ chipText() }}
    </span>
  </v-chip>
</template>

<script>

// import GladstoneIcon from '@/assets/bridges/gladstone.svg';
export default {
  name: 'Bridge',
  props: {
    bridge: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
    showSandboxLink: {
      type: Boolean,
      default: true, // Default is true, meaning it will show the sandbox link unless specified otherwise
    },
  },
  methods: {
    chipColor() {
      if (this.bridge === 'legend') {
        return 'blue';
      } else if (this.bridge === 'xnrest') {
        return '#E65100';
      } else if (this.bridge == 'gladstone') {
        return '#00897B';
      } else if (this.bridge == 'openplay') {
        return 'purple';
      }
      return 'dark-grey';
    },

    chipText() {
      if (this.bridge) {
        if (this.bridge === 'legend') {
          return this.showSandboxLink ? 'Legend (Sandbox)' : 'Legend';
        } else if (this.bridge === 'xnrest') {
          return this.showSandboxLink ? 'XnRest (Sandbox)' : 'XnRest';
        }
        return this.bridge;
      }
      if (this.version) {
        return 'Standalone';
      }
      return '';
    },
    // chipIcon() {
    //   // Return the appropriate icon based on the bridge value
    //   if (this.bridge === 'legend') {
    //     return GladstoneIcon;
    //   } else if (this.bridge === 'xnrest') {
    //     return GladstoneIcon;
    //   } else if (this.bridge === 'gladstone') {
    //     return GladstoneIcon;
    //   } else if (this.bridge === 'openplay') {
    //     return GladstoneIcon;
    //   }
    //   return GladstoneIcon; // Fallback icon
    // },
    handleChipClick() {
      if (this.showSandboxLink) {
        if (this.bridge === 'legend') {
          this.openSandbox();
        } else if (this.bridge === 'xnrest') {
          this.openLeisureHub();
        }
      }
    },
    openSandbox() {
      this.$router.push('/tenant/' + this.$route.params.id + '/sandbox');
    },
    openLeisureHub() {
      this.$router.push('/tenant/' + this.$route.params.id + '/leisurehub');
    },
  },
};
</script>

<style scoped>
.bridge {
  text-transform: capitalize;
  cursor: pointer;
}
</style>
