
<template>
    <v-layout child-flex>
    <v-card>
        <v-card-title>
         Debug
        </v-card-title>



    </v-card>
    </v-layout>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted() {
          console.log('debug');
          this.$http.post(
              process.env.VUE_APP_CONTROL_API_URL + "debug ",
              {
                'token': localStorage.getItem('token'),
                'refresh_token': localStorage.getItem('refreshToken'),
              }
          ).then(response => {
            if (response.data.warnings && response.data.warnings.length) {

              console.warn(response.data);
            }

          });
        },
        name: "debug"
    }
</script>

<style scoped>

</style>
