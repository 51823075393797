<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'Licence',
    hashIndex: 4,
    apiSuffix: 'licence',
    config: [],
    defaultConfig: [
      {
        type:1,
        key: 'centres',
        value: 'unlimited'
      },
      {
        type:1,
        key: 'client',
        valueTemplate: '{{TENANT_NAME}}'
      },
      {
        type:1,
        key: 'config',
        value: "true"
      },
      {
        type:1,
        key: 'ENABLE_HP2_API',
        value: true
      },
      {
        type:1,
        key: 'ENABLE_HOMEPORTAL_BASKET',
        value: true
      },

      {
        type:1,
        key: 'courses',
        value: 'unlimited'
      },
      {
        type:1,
        key: 'disable_terms',
        value: 0
      },
      {
        type:1,
        key: 'distributor',
        value: 'cap2'
      },
      {
        type:1,
        key: 'expires',
        value: ''
      },
      {
        type:1,
        key: 'product',
        value: 'coursepro'
      },

    ]
  }),
  name: "tenant-licence"
}
</script>
