<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'CoachPortal',
    apiSuffix: 'coachportal',
    tenantDataKey: 'coachportal_tenant',
    urlKey: 'coachportal_url',
    generateUrlString: '.coachportal.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 7;
      }
    },
  },
  name: "tenant-coachportal-service"
}
</script>
