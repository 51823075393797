<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Tools
      </v-card-title>
      <v-card-text>
        <v-row>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="blue darken-4" shaped class="pa-2">

              <v-card-title>Licence Tools

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'toolslicence' }">Create self-hosted licence</v-btn>

              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="red darken-4" shaped class="pa-2">

              <v-card-title>Import Tools

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'toolsimporter' }">Import a member spreadsheet (WIP)</v-btn>
                <br />
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="orange darken-4" shaped class="pa-2">

              <v-card-title>Competency Mapper

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'toolsmapper' }">Map competencies between tenants</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="green darken-4" shaped class="pa-2">

              <v-card-title>Create a release

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'tools-create-release' }">Create a release on AWS</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="purple darken-4" shaped class="pa-2">

              <v-card-title>Deploy a Release

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'tools-deploy-release' }">Deploy a release to AWS</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="yellow darken-4" shaped class="pa-2">

              <v-card-title>Seed Tenant with Demo Data

              </v-card-title>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'tools-seed-tenant' }">Seed Tenant</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" lg="4">
            <v-card color="lime darken-4" shaped class="pa-2">
              <v-card-title>Upload translations to Straker</v-card-title>
              <v-card-actions>
                <v-btn :to="{ name: 'tools-upload-translations' }">Upload</v-btn>
                <v-btn color="green" :href="strakerLink" target="_blank">Check status</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    strakerLink: "https://languagecloud.strakertranslations.com/login",
  }),
  name: "tools-list",
}

</script>

<style scoped>
</style>
