<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'Commsportal',
    hashIndex: 7,
    apiSuffix: 'commsportal',
    config: [],
    defaultConfig: [],
  }),
  name: "tenant-commsportal-config"
}
</script>
