
const logout = (context, {vi}) => {
    return new Promise((resolve) => {
        context.commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        delete vi.$http.defaults.headers.common['Authorization'];
        resolve();
    });
}

const login = (context, {vi}) => {
    return new Promise((resolve, reject) => {
        context.commit('auth_request')
        vi.$http.get(process.env.VUE_APP_CONTROL_API_URL + "authenticate/verify")
            .catch(function (error) {
                context.commit('auth_error', error)
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                reject(error);
                  if (error.response.status === 403) {
                      window.location.href = process.env.VUE_APP_CONTROL_API_URL + "authenticate"
                  }

            })
            .then(response => {
                if (response.data.refreshToken) {
                    localStorage.setItem('refreshToken',response.data.refreshToken );
                }
                if (response.data.token) {
                    const token = response.data.token;
                    const username = response.data.user;
                    localStorage.setItem('token', token)
                    localStorage.setItem('username', username)
                    //vi.$http.defaults.headers.common['Authorization'] = 'Bearer '+token;
                    context.commit('auth_success', token, username);
                    resolve(response);
                }
            });
    })
}

export default {
    logout,
    login
}



