<template>
    <v-layout child-flex>

    <v-card>
      <v-alert
          dense
          outlined
          type="error"
      >
        I am a work in progress. I dont currently support <strong>parent</strong> relationships and my interface is designed by a <strong>idiot</strong>
      </v-alert>
        <v-card-title>
            Member Importer
        </v-card-title>

      <v-data-table
          v-if="acquired"
          :headers="importHeaders"
          :items="importMembers"
          sort-by="key"
          class="elevation-1 config-table"
          fixed-header
      >
        <template
            v-for="header in importHeaders"
            v-slot:[`header.${header.value}`]=""
        >
              <v-select
                  v-if="header.editing"
                  :items="validCols"
                  :key="header.text"
                  v-model="header.text"
                  label=""
                  dense
                  v-on:change="updateCol(header)"
              ></v-select>
          <span v-else
                :key="header.value"
                @click="editCol(header)"
          ><strong>{{header.text}}</strong></span>
        </template>
      </v-data-table>
      <v-card-actions>
        <FileUpload
            v-if="acquired === false"
            text="Upload Spreadsheet"
            target="member/importer"
            @callback="uploadSpreadsheet"
        >
        </FileUpload>
        <v-btn
            v-if="acquired === true && this.validated === false"
            @click="validate"
        >
          Validate
        </v-btn>
        <v-btn
            v-if="this.validated"
            @click="getSql"
        >
          Download SQL
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
        v-if="acquired === true"
        @click="reset"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-layout>
</template>





<script>
    import FileUpload from "@/components/FileUpload";
    export default {
      components: {FileUpload},
      data: () => ({
        acquired: false,
        importHeaders: [],
        importMembers: [],
        validCols: [],
        outputFile: null,
        validated: false
      }),
      mounted() {
        this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "member/columns").then(response => {
          this.validCols = response.data;
        });
      },
      computed: {
        buttonText() {
          return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
        },
      },
      methods: {
        updateCol(header) {
          console.log(header);
          header.editing = false;
          console.log(this.importHeaders);
        },
        editCol(header) {
          console.log(header);
          header.editing = true;
        },
        validate() {
          this.validated = false;
          let fail = false;
          this.validCols.forEach(function(row){
            row.occurances =  this.importHeaders.filter((obj) => obj.text === row.text).length;
            if (row.occurances > 1 && row.text && row.text !== 'Ignore') {
              this.$toast.error('Duplicate column: '+row.text);
              fail = true;
            }
          }, this);
          if (!fail) {
            this.validated = true;
          }
        },
        reset() {
          this.importHeaders = [];
          this.importMembers = [];
          this.outputFile = null;
          this.acquired = false;
          this.validated = false;
        },
        getSql() {
          this.$http.post(
              process.env.VUE_APP_CONTROL_API_URL + "member/validate" ,
              {
                headers: this.importHeaders,
                members: this.importMembers,
              }
          ).then(response => {
            if (response.data.valid) {
              this.validated = true;
              if (response.data.sql) {
                let blob =  new Blob([response.data.sql], {
                  type: 'text/plain'
                });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = this.outputFile || 'output.sql'
                link.click();
              }
              response.data.warnings.forEach(function(row) {
                this.$toast.warning(row);
                console.warn(row);
              }, this);
            } else {
              this.validated = false;
              response.data.errors.forEach(function(row) {
                this.$toast.error(row);
                console.error(row);
              }, this);
            }
          });
        },
        buildHeader(data) {
          let out = [];
          let idx = 0;
          data.forEach(function(row){
            out.push(
                {
                  text: row,
                  value: idx++,
                }
            );
          })
          return out;
        },
        uploadSpreadsheet(response) {
          if (response.data.valid) {

            this.importHeaders = response.data.header;
            this.importMembers = response.data.members;
            this.outputFile = response.data.output;

            this.$toast.success(this.importMembers.length +' members imported');
            this.acquired = true;
          } else {
            this.$toast.error('Error: '+response.data.error);
          }
        },
      },
      name: "tools-importer"
    }

</script>

<style scoped>

</style>
