<template>
  <v-app id="missioncontrol">
    <div>
      <v-app-bar
          dense
          dark
          app
          class="pr-8 pl-2"
      >
        <v-toolbar-title>Mission Control</v-toolbar-title>

        <v-spacer></v-spacer>
        <OmniSearch @input="navToTenant"/>
        <v-spacer></v-spacer>
        <v-menu
            left
            bottom
            v-if="isLoggedIn()"
            class="pa-4"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-chip

                  color="indigo"
                  text-color="white"
                  v-model="user"

              >
                <v-avatar left>
                  <v-icon>mdi-account-circle</v-icon>
                </v-avatar>
                {{user}}
              </v-chip>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                @click="logout()"
            >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </div>
    <navigation v-if="isLoggedIn()"/>
<!--    <v-app-bar-->
<!--            app-->
<!--            clipped-left-->
<!--    >-->
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
<!--      <v-toolbar-title>Mission Control</v-toolbar-title>-->

<!--      <v-btn icon right>-->
<!--        <v-icon>mdi-heart</v-icon>-->
<!--      </v-btn>-->
<!--    </v-app-bar>-->

    <v-main app>
      <v-container fluid fill-height fill-width>
        <router-view :key="$route.path"/>
      </v-container>
    </v-main>
    <v-footer app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>
<script>
  import Navigation from "./components/Navigation";
  import OmniSearch from "@/components/OmniSearch";
  export default {
    components: {
      OmniSearch,
      Navigation,
    },
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
    }),
    created() {
      this.$vuetify.theme.dark = true;
    },
    computed : {
      oldisLoggedIn() {
         return this.$store.getters["user/isLoggedIn"]
      },
      user() {
        return localStorage.getItem('username');
       // return this.$store.getters["user/username"];
      }
    },
    methods: {
      navToTenant(model) {
        this.$router.push(`/tenant/${model.id}`);
      },
      isLoggedIn() {
        return this.$store.getters["user/isLoggedIn"]
      },
      logout: function () {
        this.$store.dispatch('user/logout', {vi: this})
            .then(() => {
              this.$router.push('/')

            })
      }
    }
  }
</script>

<style>
.Vue-Toastification__toast.darkMode {
  color: black;
}
.Vue-Toastification__close-button {
  color: black;
}
.v-application, a.externalLink {
  color: white !important;
  text-decoration: none;
}

</style>


