const auth_request = (state) => {
    state.status = 'loading'
}
const auth_success = (state, token, username) => {
    console.warn('mutation', token, username);
    state.status = 'success'
    state.token = token
    state.username = username
}
const auth_error = (state) => {
    state.status = 'error'
}
const logout = (state) => {
    state.status = ''
    state.token = ''
    state.username = ''
}

export default {
    auth_request,
    auth_success,
    auth_error,
    logout
}
