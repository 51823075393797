<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'ParentPortal',
    apiSuffix: 'parentportal',
    tenantDataKey: 'parentportal_tenant',
    urlKey: 'parentportal_url',
    generateUrlString: '.parentportal.cap2sandbox.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 12;
      }
    },
  },
  name: "tenant-parentportal-service"
}
</script>
