<template>
    <v-layout child-flex>
    <v-card>
        <v-card-title>
            Environments
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="tenants"
                :search="search"
                :items-per-page="10"
                class="elevation-1"
                :loading="loading"
        >
          <template v-slot:item.group_name="{ item }">
            <v-chip
                class="ma-2"
                color="blue"
                v-if="item.group_name=='blue'"
            >
              {{item.group_name.toUpperCase()}}
            </v-chip>
            <v-chip
                class="ma-2"
                color="green"
                v-else-if="item.group_name=='green'"
            >
              {{item.group_name.toUpperCase()}}
            </v-chip>
            <v-chip
                class="ma-2"
                v-else
            >
              {{item.group_name.toUpperCase()}}
            </v-chip>

          </template>
        </v-data-table>
    </v-card>
    </v-layout>
</template>





<script>
    export default {
        data () {
            return {
                search: '',
                headers: [
                  { text: 'Id', value: '_id' },
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: true,
                        value: 'environment_name',
                    },
                    { text: 'InstanceId', value: 'environment_id' },
                    { text: 'Group', value: 'group_name' },
                    { text: 'Region', value: 'region.description' },
                    { text: 'FQDN', value: 'fqdn' },
                ],
                loading: true,
                tenants: [],
            }
        },
        mounted() {
            this.$http.get(process.env.VUE_APP_CONTROL_API_URL +"environment").then(response => {
                this.tenants = response.data.environments
              this.loading = false;
            });
        },
        name: "environment-list"
    }

</script>

<style scoped>

</style>
