<template>
  <v-chip
      class="ma-2"
      :color="spanClass()"
  >
    {{ spanText() }}
  </v-chip>
</template>

<script>
export default {
  name: 'LicenceExpiryText',
  props: {
    expiry: {
      type: String,
      default: '', // You can modify this to null if expiry might be empty or not a string
    },
  },
  data() {
    return {
      state: undefined,
      days: undefined,
    };
  },
  mounted() {
    this.calculateExpiryState(); // Call a method to calculate expiry state
  },
  methods: {
    calculateExpiryState() {
      if (this.expiry) {
        const expiryDate = new Date(this.expiry);
        const currentDate = new Date();

        if (isNaN(expiryDate.getTime())) {
          this.state = -2; // Invalid date
        } else {
          this.days = (expiryDate - currentDate) / (1000 * 3600 * 24); // Calculate days difference

          if (this.days < 0) {
            this.state = -1; // Expired
          } else if (this.days < 91) {
            this.state = 0; // Expiring soon
          } else {
            this.state = 1; // Valid, not expiring soon
          }
        }
      } else {
        this.state = 1; // Default to non-expiring when expiry is empty
      }
    },
    spanClass() {
      if (!this.expiry) {
        return 'success'; // Default to 'success' when no expiry date is provided
      }
      switch (this.state) {
        case -1:
        case 0:
          return 'error'; // Red for expired or expiring soon
        case 1:
          return 'success'; // Green for valid license
        case -2:
          return 'warning'; // Yellow for invalid dates
        default:
          return 'success';
      }
    },
    spanText() {
      if (!this.expiry) {
        return 'Licence never expires'; // Default message
      }
      switch (this.state) {
        case -1:
          return `Licence expired on ${this.expiry}`;
        case 0:
          return `Licence expires in ${this.days.toFixed(0)} days (${this.expiry})`;
        case 1:
          return `Licence expires on ${this.expiry}`;
        case -2:
          return 'Invalid licence expiry date';
        default:
          return 'Licence expires on ' + this.expiry;
      }
    },
  },
};
</script>

<style scoped>
/* Optional style customization for chip states */
.v-chip.error {
  background-color: #f44336; /* Red for error */
  color: white;
}

.v-chip.success {
  background-color: #4caf50; /* Green for success */
  color: white;
}

.v-chip.warning {
  background-color: #ff9800; /* Yellow for invalid dates */
  color: white;
}
</style>
