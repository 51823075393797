<template>
    <div id="landing">
        <h1>Val</h1>

    </div>
</template>

<script>
    // import store from "@/store";

    export default {
        name: "Val",
       mounted() {
         this.$http.get(
             'https://coursepro.loc/rest/val'
         ).then(response => {
          console.warn(response);
         });


        },
    }
</script>

<style scoped>

</style>
