<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        <TenantBreadcrumb
            v-if="tenant"
            v-bind:name="tenant.tenant_name"
            :sub="configType"
        ></TenantBreadcrumb>
      </v-card-title>
      <v-form
          ref="form"
      >


  <v-card  class="ma-2, pa-2" color="grey darken-4">
    <v-card-title>Domains:
    </v-card-title>
    <v-card-text>
    <v-btn
        absolute
        dark
        fab
        bottom
        right
        color="pink"
        @click="newDomain"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
        <v-item-group
            v-for="(item, index)  in tenant.domains[apiSuffix]"
            :key="index"
            class="ma-2, pa-2"
        >
          <v-row>
            <v-col
                cols="12"
                sm="4"
                lg="4">

          <v-text-field
              v-model="item.host"
              label="Domain"
          ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="5"
                lg="5">
          <v-select
              :items="certificates"
              v-model="item.certificate"
              label="Certificate"
          ></v-select>

            </v-col>
<!--            Reserved for future UI work-->
<!--            <v-col-->
<!--                cols="12"-->
<!--                sm="2"-->
<!--                lg="2">-->
<!--              <v-select-->
<!--                  :items="getRedirects(item.host)"-->
<!--                  v-model="item.redirects"-->
<!--                  label="Redirect"-->
<!--              ></v-select>-->

<!--            </v-col>-->
            <v-col
                cols="12"
                sm="2"
                lg="2">
          <v-switch v-model="item.is_primary"   @change="setPrimary(index)" :label="`Primary?`"></v-switch>
              <v-spacer></v-spacer>

            </v-col>
            <v-col
                cols="12"
                sm="1"
                lg="1">
              <v-btn right icon color="white" @click="deleteDomain(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-icon  v-if="item.has_whitelist" class="mr-2 yellow--text" title="Has Whitelist">mdi-alert</v-icon>
            </v-col>
          </v-row>
        </v-item-group>
    </v-card-text>
  </v-card>
<v-spacer></v-spacer>
        <v-card class="ma-2, mt-5, pa-2" color="grey darken-4" v-if="tenant[tenantDataKey]">
          <v-card-title>Environments:
          </v-card-title>
          <v-card-text>
          <v-item-group             class="ma-2, pa-2">
          <v-select
              v-if="typeof tenant[tenantDataKey].web_environment_id !== 'undefined'"
              :items="filterEnvironments(tenant.region._id, 'web')"
              v-model="tenant[tenantDataKey].web_environment_id"
              label="Web Environment"
          ></v-select>

          <v-select
              v-if="typeof tenant[tenantDataKey].worker_environment_id !== 'undefined'"
              :items="filterEnvironments(tenant.region._id, 'worker')"
              v-model="tenant[tenantDataKey].worker_environment_id"
              label="Worker Environment"
          ></v-select>

<!--            <v-select-->
<!--                v-if="typeof tenant[tenantDataKey].sns_environment_id !== 'undefined'"-->
<!--                :items="filterEnvironments(tenant.region._id, 'sns')"-->
<!--                v-model="tenant[tenantDataKey].sns_environment_id"-->
<!--                label="SNS Environment"-->
<!--            ></v-select>-->
          </v-item-group>
          </v-card-text>
        </v-card>

      </v-form>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">Save</v-btn>

      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import TenantBreadcrumb from "@/components/TenantBreadcrumb";
export default {
  components: {TenantBreadcrumb},
  data: () => ({
    configType: '',
    apiSuffix: '',
    tenantDataKey: null,
    urlKey: null,
    generateUrlString: null,
    loading: true,
    tenant: {
      tenant_name: 'Loading',
      coursepro_tenant: {},
      homeportal_tenant: {},
      hp2_tenant: {},
      domains: [],
      _id: null
    },
    environments: [],
    certificates: [
      {
        text: 'None',
        value: null
      }
    ],
  }),

  computed: {
    currentUrl:
        {
          get()
          {
            if (this.tenant[this.urlKey]) {
              return this.tenant[this.urlKey];
            }
            let protocol = 'http://';
            if (this.tenant[this.tenantDataKey].certificate) {
              protocol = 'https://'
            }
            return protocol + this.tenant.tenant_name + this.generateUrlString;
          },
          set(value)
          {
            this.tenant[this.urlKey] = value
          }
        }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;

    });

    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "certificate").then(response => {
      response.data.certificates.forEach(function (row) {
        this.certificates.push({
          text: row.certificate_name + ' (' + row.domain + ')',
          value: row._id
        });
      }, this);

    });
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "environment/fast").then(response => {
      response.data.environments.forEach(function (row) {
        this.environments.push({
          text: row.environment_name,
          value: row._id,
          type: row.type,
          region: row.region,
        });
      }, this);
    });

  },
  methods: {
    //Reserver for future UI work
    // getRedirects: function (me)
    // {
    //   let select = [
    //         {
    //           text: 'None',
    //           value: null
    //         }
    //       ];
    //   this.tenant.domains[this.apiSuffix].forEach(function (item) {
    //     if (item.host !== me) {
    //       select.push({
    //         text: item.host,
    //         value: item.id
    //       });
    //     }
    //
    //   });
    //   return select;
    // },
    save: function()
    {

      if (!this.tenant.domains[this.apiSuffix].length) {
        this.$toast.error('You must specify a domain or remove the '+ this.configType +' service');
        return
      }
      let hasPrimary = false;
      this.tenant.domains[this.apiSuffix].forEach(function (item) {
        if (item.is_primary) {
          hasPrimary = true;
        }
      });
      if (!hasPrimary) {
        this.$toast.error('You must specify a primary domain');
        return;
      }
      let patch = {}
      patch.domains = {};
      patch[this.tenantDataKey] = {};

      patch.domains[this.apiSuffix] = this.tenant.domains[this.apiSuffix];

      if (this.tenant[this.tenantDataKey].web_environment_id) {
        patch[this.tenantDataKey].web_environment_id = this.tenant[this.tenantDataKey].web_environment_id;
      }
      if (this.tenant[this.tenantDataKey].worker_environment_id) {
        patch[this.tenantDataKey].worker_environment_id = this.tenant[this.tenantDataKey].worker_environment_id;
      }
      if (this.tenant[this.tenantDataKey].sns_environment_id) {
        patch[this.tenantDataKey].sns_environment_id = this.tenant[this.tenantDataKey].sns_environment_id;
      }
      this.$http.patch(
          process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id ,
          patch
      ).then(response => {
        if (response.data.error) {
          response.data.error.forEach(function(row) {
            this.$toast.error(row);

          }, this);
        }
        if (response.data.tenant) {
          this.tenant = response.data.tenant;
          this.$toast.success(this.configType +' settings successfully saved');
        }

      });

    },
    setPrimary: function (index) {
      let state = this.tenant.domains[this.apiSuffix][index].is_primary
      if (state) {
        this.tenant.domains[this.apiSuffix].forEach(function (item) {
          item.is_primary = false;
        });
        this.tenant.domains[this.apiSuffix][index].is_primary = true;
      }

    },
    deleteDomain: function (index)
    {
      if ( this.tenant.domains[this.apiSuffix][index].is_primary) {
        this.$toast.error('Cannot delete domain marked as primary');
      } else {
        this.tenant.domains[this.apiSuffix].splice(index, 1);
      }
    },
    newDomain: function ()
    {
      let cont = true;
      this.tenant.domains[this.apiSuffix].forEach(function(item){
        if (item.host === '') {
          cont = false;
        }
      });
      if (!cont) {
        return;
      }
        this.tenant.domains[this.apiSuffix].push({
          host: '',
          is_primary: false,
        });
      },
    filterEnvironments: function(region, environmentType) {
      let type = this.getEnvType(environmentType);
      return this.environments.filter(function(row) {
        return row.region === region && row.type === type;
      });
    }
  },
name: "tenant-services-base"
}
</script>

<style scoped>

</style>
