<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Competency Mapping from {{ mapping.from.tenant_name}} -> {{ mapping.to.tenant_name}}
      </v-card-title>

      <template>
        <v-data-table
            :headers="levelHeaders"
            :items="getLevels()"
            :expanded.sync="levels_expanded"
            :single-expand="true"
            item-key="name"
            show-expand
            class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            ({{item.id}}) {{item.name}}
          </template>
          <template v-slot:item.to="{ item }">
            <CompetencySelect
              v-bind:item="item"
              v-bind:selects="getSelects('levels')"
              v-bind:map="mapping.map.levels"
            ></CompetencySelect>

          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">

              <v-data-table
                  :headers="groupHeaders"
                  :items="getGroups(item)"
                  :expanded.sync="groups_expanded"
                  item-key="name"
                  show-expand
                  class="elevation-1"
                  disable-pagination
                  hide-default-footer
                  hide-default-header
              >
                <template v-slot:item.name="{ item }">
                  ({{item.id}}) {{item.name}}
                </template>
                <template v-slot:item.to="{ item }">
                  <CompetencySelect
                      v-bind:item="item"
                      v-bind:selects="getSelects('groups', item.level)"
                      v-bind:map="mapping.map.groups"
                  ></CompetencySelect>

                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
            <v-data-table
                :headers="competencyHeaders"
                :items="getCompetencies(item)"
                item-key="name"
                class="elevation-1"
                disable-pagination
                hide-default-footer
                hide-default-header
            >
              <template v-slot:item.name="{ item }">
                ({{item.id}}) {{item.name}}
              </template>
              <template v-slot:item.to="{ item }">
                <CompetencySelect
                    v-bind:item="item"
                    v-bind:selects="getSelects('competencies', item.level, item.group)"
                    v-bind:map="mapping.map.competencies"
                ></CompetencySelect>
              </template>
        </v-data-table>

                  </td>
                </template>
        </v-data-table>
      </td>



      </template>
      </v-data-table>
    </template>

    </v-card>



  </v-layout>
</template>

<script>

import CompetencySelect from "@/components/CompetencySelect";
export default {
  components: {CompetencySelect},
  data: () => ({
    levelHeaders: [
      {
        text: 'Levels',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Maps to', value: 'to' },
    ],
    groupHeaders: [
      {
        text: 'Groups',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Maps to', value: 'to' },
    ],
    competencyHeaders: [
      {
        text: 'Competency',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Maps to', value: 'to' },
    ],
    levels_expanded: [],
    groups_expanded: [],
    mapping: {
      from: {},
      from_structure: [],
      to: {},
      map: [],
    },
    selectOptions: {
      levels: {},
      groups: {},
      competencies: {},
    },
    loading: true,







  }),
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "competency-mapping/" + this.$route.params.hash).then(response => {
      this.mapping = response.data.mapping;
      this.selectOptions = response.data.selects
      console.warn(this.mapping.map.competencies);
      this.loading = false;
    });
  },
  methods: {
    getCompetencies(group) {
      return Object.values(this.mapping.from_structure[group.level].groups[group.id].competencies);
    },
    getGroups(level) {
      return Object.values(this.mapping.from_structure[level.id].groups);
    },
    getLevels() {
      return Object.values(this.mapping.from_structure);
    },
    getSelects(type, level, group) {
      if (type === 'levels') {
        //console.warn(this.selectOptions.levels.length);
        return this.selectOptions.levels;
      } else if (type === 'groups') {
        if (this.mapping.map.levels[level]) {
          return  this.selectOptions.groups[this.mapping.map.levels[level]];
        }
      return [];
      } else {
        if (this.mapping.map.levels[level]) {
          let newLevel = this.mapping.map.levels[level];
          if (this.mapping.map.groups[group]) {
            let newGroup = this.mapping.map.groups[group];
            return this.selectOptions.competencies[newLevel][newGroup];

          }
        }
        return [];
      }
    }

  },
  name: "competencymapping"
}
</script>

<style scoped>

</style>
