<template>
  <div>

  <span v-if="link">
      <router-link
          v-if="$props.name"
          :to="{ name: 'tenant', params: { id: $route.params.id }}"
      >
    {{ $props.name }}</router-link>
  </span>
  <span v-else>
    <span
       v-if="$props.name"
          >
    {{ $props.name }}
  </span>
  </span>
    <span v-if="$props.sub"> - {{ $props.sub }}</span>
    <span v-if="$props.timestamp"> (as of {{formatTimestamp($props.timestamp)}})</span>

    <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        v-if="!$props.loading"

    ></v-progress-linear>

  </div>


</template>

<script>

export default {
  name: 'TenantBreadcumb',
  props: {
    name: {
      type: String,
      default: '',
    },
    link: {
      type: Boolean,
      default: true
    },
    sub: {
      type: String,
      default: '',
    },
    timestamp: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ucfirst: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    formatTimestamp: function(ts) {
      let date = new Date(ts * 1000);
      const today = new Date()
      if (date.getDate() == today.getDate() &&
          date.getMonth() == today.getMonth() &&
          date.getFullYear() == today.getFullYear()) {
        return date.toLocaleTimeString();
      }

        return date.toLocaleString();

    }
  }
}
</script>

<style lang="sass">

</style>
