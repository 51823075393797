var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Competency Mapping from "+_vm._s(_vm.mapping.from.tenant_name)+" -> "+_vm._s(_vm.mapping.to.tenant_name)+" ")]),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.levelHeaders,"items":_vm.getLevels(),"expanded":_vm.levels_expanded,"single-expand":true,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.levels_expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.id)+") "+_vm._s(item.name)+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('CompetencySelect',{attrs:{"item":item,"selects":_vm.getSelects('levels'),"map":_vm.mapping.map.levels}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.getGroups(item),"expanded":_vm.groups_expanded,"item-key":"name","show-expand":"","disable-pagination":"","hide-default-footer":"","hide-default-header":""},on:{"update:expanded":function($event){_vm.groups_expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.id)+") "+_vm._s(item.name)+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('CompetencySelect',{attrs:{"item":item,"selects":_vm.getSelects('groups', item.level),"map":_vm.mapping.map.groups}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.competencyHeaders,"items":_vm.getCompetencies(item),"item-key":"name","disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.id)+") "+_vm._s(item.name)+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('CompetencySelect',{attrs:{"item":item,"selects":_vm.getSelects('competencies', item.level, item.group),"map":_vm.mapping.map.competencies}})]}}],null,true)})],1)]}}],null,true)})],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }