<template>
  <v-navigation-drawer app :mini-variant.sync="isMobile" permanent :expand-on-hover="isMobile" width="160">
    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" :to="item.path">
        <v-list-item-icon class="mr-3">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', 'path': "/dashboard" },
        { title: 'Monitor', icon: 'mdi-binoculars', 'path': "/monitor" },
        { title: 'Cron Statistics', icon: 'mdi-clock-time-four-outline', 'path': "/cron" },
        { title: 'Tenants', icon: 'mdi-cube-outline', 'path': "/tenant" },
        { title: 'Environment', icon: 'mdi-server', 'path': "/environment" },
        { title: 'Databases', icon: 'mdi-database-outline', 'path': "/database" },
        { title: 'Tools', icon: 'mdi-tools', 'path': "/tools" },
        { title: 'About', icon: 'mdi-help-box', 'path': "/about" },
      ],
      right: null,
      drawer: true,
      sync: true,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  "name": "navigation"
}
</script>
