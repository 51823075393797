<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        <TenantBreadcrumb
            v-bind:name="tenant.tenant_name"
            :sub="subName()"
        ></TenantBreadcrumb>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
          :headers="headers"
          :items="system"
          :search="search"
          sort-by="key"
          class="elevation-1 config-table"
          fixed-header
      >
      </v-data-table>
    </v-card>

  </v-layout>
</template>
<script>
import TenantBreadcrumb from "@/components/TenantBreadcrumb";
export default {
  components: {TenantBreadcrumb},
  data: () => ({
    tenant: {
      'tenant_name': 'Loading',
      '_id': null
    },
    search: '',
    system: [],
    headers: [
      { text: 'Name', value: 'key', sortable: true,  width: "50%" },
      {text: 'Value', value: 'value'  },
    ],
    configType: 'CoursePro',
  }),
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;
      this.system = [];
      Object.entries( this.tenant.system).map(item => {
        this.system.push({
          key: item[0],
          value: item[1],
        })
      })

    });
  },
  methods: {
    subName() {
     return  this.configType + '  System (read only)'
    }
  },
  name: "tenant-coursepro-system"
}
</script>
