<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'HomePortal',
    apiSuffix: 'homeportal',
    tenantDataKey: 'homeportal_tenant',
    urlKey: 'homeportal_url',
    generateUrlString: '.courseprogress.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 3;
      }
    },
  },
  name: "tenant-homeportal-service"
}
</script>
