<template>
    <div id="landing">
        <h1>Landing Page</h1>

    </div>
</template>

<script>
    // import store from "@/store";

    export default {
        name: "landing",
       mounted() {
          if (this.$store.getters["user/isLoggedIn"]) {
            this.$router.push('/dashboard');
          } else {
           this.$store.dispatch('user/login', {vi: this})
                .then(() => {
                  this.$router.push('/dashboard')
                });
          }
        },
    }
</script>

<style scoped>

</style>
