<template>
  <v-container fluid grid-list-md>

    <v-layout row wrap>
      <v-flex d-flex xs12 sm12 md12 align-start>
        <h2>Monitor</h2>
        <v-spacer/>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details

      ></v-text-field>
      </v-flex>
      <v-flex  xs12 sm6 md4 lg3
              v-for="(mon,i) in filteredMonitors"
              :key="i">
        <v-card
            cols="1"
            outlined
            color="grey"
            dark
            class="ma-2"
            v-if="mon.active==0"
            @click="openDetail(mon.id)"
        >
          <v-card-title class="text-center text-truncate"
                        caption

          >{{ mon.label }}</v-card-title>
          <v-card-text>UP since {{ niceDate(mon.last_offline)}}</v-card-text>
        </v-card>
        <v-card
            cols="1"
            outlined
            color="green"
            dark
            class="ma-2"
            v-else-if="mon.status == 1 && mon.warning_count == 0"
            @click="openDetail(mon.id)"
        >
          <v-card-title class="text-center text-truncate"
                        caption

          >{{ mon.label }}</v-card-title>
          <v-card-text>UP since {{ niceDate(mon.last_offline)}}</v-card-text>
        </v-card>
        <v-card
            cols="1"
            outlined
            color="yellow darken-3"
            dark
            class="ma-2"
            v-else-if="mon.warning_count"
            @click="openDetail(mon.id)"
        >
          <v-card-title class="text-center text-truncate"
                        caption

          >{{ mon.label }}</v-card-title>
          <v-card-text>WARNING since {{ niceDate(mon.last_check)}}</v-card-text>
        </v-card>
        <v-card
            cols="1"
            outlined
            color="red"
            dark
            class="ma-2"
            v-else
            @click="openDetail(mon.id)"
        >
          <v-card-title>{{ mon.label }} </v-card-title>
          <v-card-text>DOWN since {{ niceDate(mon.last_online)}}</v-card-text>
        </v-card>

      </v-flex>
    </v-layout>
  </v-container>

</template>


<script>
export default {
  data() {
    return {
      monitors: [],
      search:''
    }
  },
  methods: {
    openDetail(id) {
      this.$router.push('/monitor/'+ id );
    },
    niceDate(date) {
      if (date == '0000-00-00 00:00:00') {
        return 'records began';
      }
      return date;
    }
  },
  computed: {
    filteredMonitors() {
      return this.monitors.filter(monitor => {
        const filter = this.search.toUpperCase();
        const hasIdMatch = String(monitor.id).includes(filter);
        const hasNameMatch = monitor.label.toUpperCase().includes(filter);
       return hasIdMatch || hasNameMatch;
      })
    }
  },

  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "monitor").then(response => {
      this.monitors = response.data.monitors;
    });
  },


  name: "monitor"
}
</script>

<style scoped>

</style>
