<template>
  <v-select
      :items="selects"
      v-model="computedValue"
      label=""
  ></v-select>
</template>

<script>

export default {
  name: 'CompetencySelect',
  props: {
    item: {
      type: Object,
      default: function(){},
    },
    selects: {
      type: Array,
      default: function(){},
    },
    map: {
      type: Object,
      default: function(){},
    }
  },
  computed: {
    selectDebug() {
      return this.$props.selects.map(item => {
        item.text = "(" +item.value + ") " + item.text;
        return item;
      });
    },
    computedValue: {
      get() {
        if (this.$props.map[this.$props.item.id]) {
          return this.$props.map[this.$props.item.id];
        }
        return null;
      },
      set(newValue) {
        this.$props.map[this.$props.item.id] = newValue;
        console.warn('New ' + newValue)

      }
    }
  },

}
</script>

<style lang="sass">

</style>
