//require("@/routes.js");

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './plugins/base'
import AsyncComputed from 'vue-async-computed';
import router from './router';
import Vuex from 'vuex';
import { http } from '@/services/http';
import store from "@/store";
import dialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;
Vue.prototype.$http = http;

Vue.use(vuetify);
Vue.use(Vuex);
Vue.use(dialog);
Vue.use(AsyncComputed);
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 8000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
    toastClassName: 'darkMode',
});


document.title = 'Mission Control'

new Vue({
  'store': store,
  vuetify,
    router,
  render: h => h(App)
}).$mount('#missioncontrol');
