<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-5>
                <h1>Error - page not found</h1>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    export default {};
</script>
