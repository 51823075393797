<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        <TenantBreadcrumb
          v-bind:name="tenant.tenant_name"
          sub="Soap Sandbox"
          >
        </TenantBreadcrumb>
      </v-card-title>
      <v-card-text>
        <v-select
            :items="soapMethods"
            label="Soap Method"
            outlined
            v-model="selectedMethod"
            v-on:change="changeMethod"

        ></v-select>

        <v-form >
          <div v-for="(item, idx) in formElements" :key="idx" >
            <span
            v-if="item.description">
              {{item.description}}
            </span>
            <v-text-field
                v-model= "values[idx]"
                v-if="item.type === 'text'"
                :label=item.label
            ></v-text-field>
            <v-textarea
                v-model= "values[idx]"
                v-if="item.type === 'textarea'"
                :label=item.label
            ></v-textarea>
            <v-text-field
                v-if="item.type === 'date'"
                v-model= "values[idx]"
                :label=item.label
            ></v-text-field>
            <v-switch
                v-if="item.type === 'boolean'"
                v-model= "values[idx]"
                :label=item.label
            ></v-switch>
          </div>
          <v-btn
              right
              :loading="loading"
              :disabled="loading || !selectedMethod"
              @click="submitRequest"
          >
            Submit request
          </v-btn>
        </v-form>

        <v-row>
          <v-col
              v-if="soapResponse.time"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(252, 186, 3,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>Request:</v-card-title>
              <v-card-text>
                <div v-if="soapResponse.time.session">
                  Session: {{soapResponse.time.session}} seconds
                </div>
                <div v-if="soapResponse.time.method">
                  Method: {{soapResponse.time.method}} seconds
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col
              v-if="soapResponse.responseMessage"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(186, 17, 48,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Response Message:</v-card-title>
              <v-card-text>

                {{soapResponse.responseMessage}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col
              v-if="soapResponse.response"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(12, 24, 196,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Response:</v-card-title>
              <v-card-text>
                <PrettyXml  v-bind:xml="soapResponse.response"></PrettyXml>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              v-if="soapResponse.responseHeaders"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(12, 24, 196,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Response Headers:</v-card-title>
              <v-card-text>
                <pre>{{soapResponse.responseHeaders}}</pre>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>

          <v-col
              v-if="soapResponse.request"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(9, 176, 95,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Request:</v-card-title>
              <v-card-text>
                <v-card-text>
                  <PrettyXml  v-bind:xml="soapResponse.request"></PrettyXml>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>

          <v-col
              v-if="soapResponse.requestHeaders"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(9, 176, 95,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Request Headers:</v-card-title>
                <v-card-text>
                  <pre>{{soapResponse.requestHeaders}}</pre>
                </v-card-text>
            </v-card>
          </v-col>
        </v-row>






        <v-row>

          <v-col
              v-if="soapResponse.responseMessage"
              cols="12"
              sm="12"
              lg="12"
          >
            <v-card
                color="rgb(186, 17, 48,0.4)"
                shaped
                class="pa-2"

            >
              <v-card-title>SOAP Response Message:</v-card-title>
              <v-card-text>

                {{soapResponse.responseMessage}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>



  </v-layout>
</template>

<script>

import TenantBreadcrumb from "@/components/TenantBreadcrumb";
import PrettyXml from "@/components/PrettyXml";
export default {
  components: {TenantBreadcrumb, PrettyXml},
  data: () => ({
    values: [],
    loading: false,
    soapResponse: [],
    soapMethods: [],
    selectedMethod: null,
    formElements: [],
    tenant: {
      tenant_name: 'Loading',
      coursepro_tenant: {},
      _id: null
    },
  }),
  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "tenant/" + this.$route.params.id).then(response => {
      this.tenant = response.data.tenant;
      this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "soap").then(response => {
        this.soapMethods =  response.data.methods;
        console.log(this.soapMethods);
      });
    });
  },

  methods: {
    valueModel: function(idx) {
      return this.values[idx];
    },
    elementId: function(idx) {
      return 'fe-' + idx;
    },
    changeMethod: function () {
      this.soapResponse = {};
      this.values = [];
      if (this.selectedMethod) {
        this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "soap/" + this.selectedMethod).then(response => {
          this.formElements = response.data;
        });
      }
    },
    submitRequest: function() {
      if (! this.selectedMethod ) {
        this.$toast.error('Choose a method first');
        return;
      }
      this.loading = true;
      this.soapResponse = {};
      let formData = new FormData();
      formData.append('tenant_id', this.tenant._id);
      Object.keys(this.formElements).forEach( row => {
        let value = this.values[row];
        if (value === undefined) {
          value = null;
        }
        formData.append(row, value);
      }, formData);

      let vue = this;
      this.$http.post(
          process.env.VUE_APP_CONTROL_API_URL + "soap/" + this.selectedMethod,
          formData
      ).then(response => {
        this.soapResponse = response.data;
      }).catch(
          function(error) {
            vue.$toast.error('Failed to send method '+ vue.selectedMethod);
            return Promise.reject(error)
          }
      ).finally(
          function() {vue.loading = false}
      );
    }
  },
  name: "tenantsandbox"
}
</script>

<style scoped>

</style>
