<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'Insight',
    apiSuffix: 'insight',
    tenantDataKey: 'insight_tenant',
    urlKey: 'insight_url',
    generateUrlString: '.insightnps.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 9;
      }
      if (type === 'worker') {
        return 10;
      }
    },
  },
  name: "tenant-insight-service"
}
</script>
