<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Upload translations to Straker
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="types" v-model="type" :disabled="loading.upload" label="Select translation type" @click="reload" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="branches" v-model="branch" :loading="loading.branches" :disabled="!type" label="Select an branch" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-2 mr-1" color="primary" :disabled="!type" @click="uploadTranslationsToStraker" :loading="loading.upload">
          Upload translations
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import { http, apiUrl } from '@/services/http';

export default {
  name: 'ToolsCreateRelease',
  components: {},
  data: () => ({
    loading: {
      upload: false,
      branches: false,
    },
    type: '',
    branch: '',
    types: [
      {'text': 'Strive', value: 'strive'},
      {'text': 'Strive BG', value: 'strive-bg'},
      {'text': 'Homeportal/Onlinejoining', value: 'homeportal'},
    ],
    branches: [],
  }),
  methods: {
    async uploadTranslationsToStraker() {
      try {
        this.loading.upload = true;
        await http.post(`${apiUrl}/straker/upload-translations`, { type: this.type, branch: this.branch });
        this.$toast.success(`Successfully uploaded ${this.type} translations to Straker`);
      } catch (e) {
        let err = '?';
        if (e.response && e.response.data) {
          err = e.response.data.error;
        }
        this.$toast.error(`Failed to upload translations to Straker - ${err}`);
      } finally {
        this.loading.upload = false;
      }
    },
    async getBranches() {
      const params = { app: 'coursepro' };
      const response = await http.get(`${apiUrl}/repos/branches`, { params });
      this.branches = response.data;
    },
    async reload() {
      if (!this.branches.length) {
        this.loading.branches = true;
        await this.getBranches();
        this.loading.branches = false;
      }
    },
  }
}

</script>

<style scoped></style>
