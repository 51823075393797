<template>
  <v-layout child-flex>

    <v-card v-if="monitorIsLoaded">
      <v-card-title>
        Monitor for {{ monitor.label }} [<a :href="monitor.host">{{monitor.host}}</a>]
        <v-chip
            class="ma-2"
            large
            color="green"
            v-if="monitor.status"
        >

          <v-avatar centre>
            <v-icon large>mdi-check-bold</v-icon>
          </v-avatar>
        </v-chip>
        <v-btn
            class="ma-2"
            outlined
            color="indigo"
            @click="openTenant(monitor.tenant)"
        >
          Go to tenant
        </v-btn>
        <v-spacer></v-spacer>
        Active:
        <v-switch
            left
            v-model="monitor.active"
            @change="toggleActive()"
        ></v-switch>
      </v-card-title>

      <v-row>
        <v-col
            cols="21"
            sm="12"
            lg="6">
          <v-simple-table>
            <thead>
            <tr>
              <th colspan="2">Status
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Host:</td>
              <td>{{monitor.host}}</td>
            </tr>
            <tr
            v-if="monitor.status">
              <td>Status:</td>
              <td>    <v-chip
                  class="ma-2"
                  color="green"
                  text-color="white"
              >
                ONLINE
              </v-chip></td>
            </tr>
            <tr
                v-else>
              <td>Status:</td>
              <td> <v-chip
                  class="ma-2"
                  color="red"
                  text-color="white"
              >
                OFFLINE
              </v-chip></td>
            </tr>
            <tr>
              <td>Last Latency:</td>
              <td>{{monitor.response_time * 1000}} milliseconds</td>
            </tr>
            <tr>
              <td>Last Online:</td>
              <td>{{monitor.last_online}}</td>
            </tr>
            <tr>
              <td>Last Offline:</td>
              <td>{{monitor.last_offline}}</td>
            </tr>
            <tr>
              <td>Last Check:</td>
              <td>{{monitor.last_check}}</td>
            </tr>
            <tr>
              <td>Total Checks:</td>
              <td>{{monitor.uptimeData.values.length - 1}}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            lg="6">
          <v-simple-table>
            <thead>
            <tr>
              <th colspan="2">Output
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Last Response:</td>
              <td><pre>{{monitor.last_response}}</pre></td>
            </tr>
            <tr>
              <td>Last Error:</td>
              <td>{{monitor.last_error}}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
            sm="12"
            lg="6">
          <v-card
              class="mx-auto"
          >
            <v-card-title>
              <v-row align="start">
                <div class="caption text-uppercase">
                  Uptime
                </div>
                <div>
          <span
              class="display-2 font-weight-black"
              v-text="monitor.uptimepc || '—'"
          ></span>
                  <strong v-if="monitor.uptimepc">%</strong>
                </div>
              </v-row>
            </v-card-title>

            <v-sheet color="transparent">
              <v-sparkline
                  :value="uptime.value"
                  :smooth="uptime.radius || false"
                  :padding="uptime.padding"
                  :line-width="uptime.width"
                  :label-size="3"
                  :gradient="uptime.gradient"
                  :stroke-linecap="uptime.lineCap"
                  :fill="uptime.fill"
                  :auto-line-width="uptime.autoLineWidth"
                  auto-draw
              >
                <template v-slot:label="item">
                  {{uptime.label[item.index]}}
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="12"
            lg="6">
        <v-card
            class="mx-auto"
        >
          <v-card-title>
            <v-row align="start">
              <div class="caption text-uppercase">
                Average latency
              </div>
              <div>
          <span
              class="display-2 font-weight-black"
              v-text="monitor.averageLatency || '—'"
          ></span>
                <strong v-if="monitor.averageLatency">ms</strong>
              </div>
            </v-row>
          </v-card-title>

          <v-sheet color="transparent">
            <v-sparkline
                :value="latency.value"
                :smooth="latency.radius || false"
                :padding="uptime.padding"
                :line-width="latency.width"
                :label-size="3"
                :gradient="latency.gradient"
                :stroke-linecap="latency.lineCap"
                :fill="latency.fill"
                :auto-line-width="latency.autoLineWidth"
                auto-draw
            >
              <template v-slot:label="item">
                {{latency.label[item.index]}}
              </template>
            </v-sparkline>
          </v-sheet>
        </v-card>
        </v-col>
      </v-row>



      </v-card>

  </v-layout>
</template>


<script>
export default {
  data() {
    return {
      monitorIsLoaded: false,
      monitor: null,
      uptime: {
        gradient: ['#00ff00', '#00ff00','#00ff00','#ffd200', '#ff0000','#ff0000','#ff0000'],
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        label: [],
        value: [],
        fill: false,
        type: 'trend',
        autoLineWidth: false,
      },
      latency: {
        gradient: ['#ff0000', '#ff0000','#ff0000','#ffd200','#00ff00','#00ff00','#00ff00'],
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        label: [],
        value: [],
        fill: false,
        type: 'trend',
        autoLineWidth: false,
      },
    }
  },
  methods: {
    openTenant(id) {
      this.$router.push('/tenant/'+ id );
    },
    statusText(status) {
      if (status == 1) {
        return 'Online'
      }
      return 'Offline'
    },
    statusColor(status) {
      if (status == 1) {
        return 'green'
      }
      return 'red'
    },
    toggleActive: function()
    {

      this.$http.patch(
          process.env.VUE_APP_CONTROL_API_URL + "monitor/" + this.$route.params.id ,
          {
            active: this.monitor.active
          }
      ).then(response => {
        if (response.data.error) {
          this.$toast.error(response.data.error);
        }
        if (response.data.monitor) {
          this.monitor = (response.data.monitor);
          this.uptime.value = this.monitor.uptimeData.values;
          this.uptime.label = this.monitor.uptimeData.labels;
          this.latency.value = this.monitor.latencyData.values;
          this.latency.label = this.monitor.latencyData.labels;
          this.monitorIsLoaded = true;
        }

      });
    },
  },

  mounted() {
    this.$http.get(process.env.VUE_APP_CONTROL_API_URL + "monitor/"+this.$route.params.id).then(response => {
      this.monitor = (response.data.monitor);
      this.uptime.value = this.monitor.uptimeData.values;
      this.uptime.label = this.monitor.uptimeData.labels;
      this.latency.value = this.monitor.latencyData.values;
      this.latency.label = this.monitor.latencyData.labels;
      this.monitorIsLoaded = true;

    });
  },


  name: "monitoritem"
}
</script>

<style scoped>

</style>
