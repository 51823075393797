<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>About</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            Version: {{ version }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
  export default {
    name: "about",
    computed: {
      version() {
        return '?';
      },
    }
  }
</script>

<style scoped>

</style>
