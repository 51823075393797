<script>
import TenantServicesBase from "@/components/TenantServicesBase";
export default {
  extends: TenantServicesBase,
  data: () => ({
    configType: 'Strive',
    apiSuffix: 'strive',
    tenantDataKey: 'strive_tenant',
    urlKey: 'strive_url',
    generateUrlString: '.strive-api.cap2sandbox.co.uk'

  }),
  methods: {
    getEnvType: function(type) {
      if (type === 'web') {
        return 8;
      }
    },
  },
  name: "tenant-strive-service"
}
</script>
