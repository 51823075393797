var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"child-flex":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Tenants "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.filters,"label":'Filter (' + (_vm.tenants.length - _vm.tenantsData.length) + ' items hidden)',"item-text":"text","item-value":"value"},on:{"change":_vm.saveFilter},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('span',{staticClass:"text-sm-body-2 ml-4"},[_vm._v("Show inactive")]),_vm._v(" "),_c('v-switch',{staticClass:"pa-0 mt-4 ml-2",attrs:{"dense":""},on:{"change":_vm.saveFilter},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tenantsData,"search":_vm.search,"item-key":"tenantsData._id","items-per-page":10,"loading":_vm.loading,"disable-pagination":_vm.tenantsData.length < 11,"hide-default-footer":_vm.tenantsData.length < 11},on:{"dblclick:row":_vm.handleRowDoubleClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No results found. ("+_vm._s(_vm.tenants.length)+" items filtered) ")]},proxy:true},{key:"item.region",fn:function(ref){
var item = ref.item;
return [(item.region.startsWith('us'))?_c('span',{attrs:{"title":"us-east-1"}},[_c('UsFlag',{attrs:{"title":"us-east-1"}})],1):(item.region.startsWith('eu'))?_c('span',{attrs:{"title":"eu-west-1"}},[_c('EuFlag',{attrs:{"title":"eu-west-1"}})],1):(item.region.startsWith('ap'))?_c('span',{attrs:{"title":"ap-sydney-1"}},[_c('AuFlag',{attrs:{"title":"ap-sydney-1"}})],1):_vm._e()]}},{key:"item.services",fn:function(ref){
var item = ref.item;
return [_c('ServiceStrip',{attrs:{"item":item}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.tenant_status=='1')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","small":""}},[_vm._v(" Yes ")]):_vm._e(),(item.tenant_status=='0')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","small":""}},[_vm._v(" No ")]):_vm._e()]}},{key:"item.licence_expires",fn:function(ref){
var item = ref.item;
return [(item.coursepro_tenant)?_c('LicenceExpiry',{attrs:{"expiry":item.licence_expires}}):_vm._e()]}},{key:"item.total_members",fn:function(ref){
var item = ref.item;
return [(item.total_members)?_c('span',[_vm._v(" "+_vm._s(item.total_members.toLocaleString())+" ")]):_c('span',[_vm._v(" n/a ")])]}},{key:"item.db_host_label",fn:function(ref){
var item = ref.item;
return [((item.coursepro_db_host_label) || (item.insight_db_host_label))?_c('span',[(item.coursepro_db_host_label)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"orange","x-small":"","label":"","text-color":"black"}},[_vm._v(" "+_vm._s(item.coursepro_db_host_label)+" ")]):_vm._e(),(item.insight_db_host_label)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"brown","x-small":"","label":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.insight_db_host_label)+" ")]):_vm._e()],1):_vm._e()]}},{key:"item.coursepro_bridge",fn:function(ref){
var item = ref.item;
return [(item.coursepro_tenant)?_c('Bridge',{attrs:{"bridge":item.coursepro_bridge,"version":item.coursepro_version,"showSandboxLink":false}}):_vm._e()]}},{key:"item.deployment_group",fn:function(ref){
var item = ref.item;
return [(item.deployment_group=='dev')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red darken-4"}},[_vm._v(" DEV ")]):_vm._e(),(item.deployment_group=='staging')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"yellow darken-4","small":""}},[_vm._v(" STAGE ")]):(item.deployment_group=='production')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green darken-4","small":""}},[_vm._v(" PROD ")]):(item.deployment_group)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.deployment_group.toUpperCase())+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"to":{ name: 'tenant', params: { id: item._id }},"color":"primary"}},[_vm._v(" View ")])]}}])}),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.tenantListCacheBust()}}},[_vm._v("Refresh")]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"pink"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add tenant")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.newTenant.tenant),callback:function ($$v) {_vm.$set(_vm.newTenant, "tenant", $$v)},expression:"newTenant.tenant"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.regions,"label":"Region","dense":""},model:{value:(_vm.newTenant.region),callback:function ($$v) {_vm.$set(_vm.newTenant, "region", $$v)},expression:"newTenant.region"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":_vm.accounts,"label":"Account","dense":""},model:{value:(_vm.newTenant.account),callback:function ($$v) {_vm.$set(_vm.newTenant, "account", $$v)},expression:"newTenant.account"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }