<script>
import TenantConfigBase from "@/components/TenantConfigBase";
export default {
  extends: TenantConfigBase,
  data: () => ({
    configType: 'Insight',
    hashIndex: 6,
    apiSuffix: 'insight',
    config: [],
    defaultConfig: [
      {
        type: 1,
        key: 'DB_HOST',
        value: ''
      },
      {
        type: 1,
        key: 'DB_DATABASE',
        value: ''
      },
      {
        type: 1,
        key: 'DB_USERNAME',
        value: ''
      },
      {
        type: 1,
        key: 'DB_PASSWORD',
        value: ''
      },
    ],
  }),
  name: "tenant-insight-config"
}
</script>
